import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import { AuthContext } from "../../contexts/Auth.context";
import { NotificationsOutlined } from "@mui/icons-material";
import categories from "../../utils/data/Categories";
import CategoryCard from "../cards/CategoryCard";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";
import { sortAppointmentsByDateAndTime } from "../../utils/others/calendar.utils";
import CustomButton from "../others/CustomButton";
import { SmallLoader } from "../loading/Loader";
import AppointmentCardSmall from "../cards/AppointmentCardSmall";
import WelcomeCard from "../cards/WelcomeCard";

const UserWidget = () => {
  const navigate = useNavigate();
  const { userData, fetchUserData } = useContext(AuthContext);
  const {
    setShowBottomNavigator,
    setActiveCategory,
    setActiveBottomNavText,
    setTopLabel,
  } = useContext(ActiveSidebarContext);

  const [appointmentList, setAppointmentList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setShowBottomNavigator(true);
    setTopLabel("Dashboard");
    getUserAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveBottomNavText, setShowBottomNavigator]);

  const handleCategoryCardSelection = (category) => {
    setActiveCategory(category.toLowerCase());
    navigate("/search");
  };

  const getUserAppointments = useCallback(async () => {
    if (!userData) return;
    setListLoading(true);
    try {
      const q = query(
        collection(db, "userAppointments"),
        where(
          userData?.role === "patient" ? "userId" : "serviceId",
          "==",
          userData.uid
        )
      );
      const querySnapshot = await getDocs(q);
      const appointments = [];
      const today = new Date();

      // Set the start and end of today
      const startOfToday = new Date(today.setHours(0, 0, 0, 0)); // Start of today (00:00:00)
      const endOfToday = new Date(today.setHours(23, 59, 59, 999)); // End of today (23:59:59.999)

      querySnapshot.forEach((doc) => {
        const currentData = doc.data();
        const bookedDate = new Date(currentData.bookedDate);

        // Check if bookedDate is within today
        if (
          bookedDate >= startOfToday &&
          bookedDate <= endOfToday &&
          !currentData.isCompleted
        ) {
          appointments.push({ id: doc.id, ...currentData });
        }
      });

      const result = sortAppointmentsByDateAndTime(appointments);
      setAppointmentList(result);
    } catch (error) {
      setAppointmentList([]);
      console.error("Error while getting appointments:", error);
    } finally {
      setListLoading(false);
    }
  }, [userData]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: customColors.color_primary,
            fontFamily: "Poppins",
            fontWeight: "bold",
          }}
        >
          {`Hi 
        ${
          userData?.role === "doctor" &&
          !userData?.firstName?.toLowerCase()?.startsWith("dr.")
            ? "Dr. "
            : ""
        }
        ${userData?.firstName} ${userData?.lastName}`}
        </Typography>

        <IconButton>
          <NotificationsOutlined
            sx={{ color: customColors.color_primary, fontSize: "1.75rem" }}
          />
        </IconButton>
      </Box>

      <WelcomeCard />

      <Box sx={{ display: "flex", flexWrap: "wrap", my: "1rem" }}>
        {categories.map((category, index) => (
          <CategoryCard
            key={index}
            category={category}
            onPress={() => handleCategoryCardSelection(category?.name)}
          />
        ))}
      </Box>

      {listLoading ? (
        <SmallLoader />
      ) : (
        <>
          {appointmentList.length === 0 && userData.role === "patient" && (
            <CustomButton
              label={"Book An Appointment for Today"}
              onClick={() => handleCategoryCardSelection("doctor")}
            />
          )}
          {appointmentList.length > 0 && (
            <>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  color: customColors.color_primary,
                }}
              >
                Today's Appointments
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: "1rem",
                }}
              >
                {appointmentList.map((appointment) => (
                  <AppointmentCardSmall
                    key={appointment.id}
                    appointment={appointment}
                    userData={userData}
                    onClick={() =>
                      console.log(`Navigate to appointment ${appointment.id}`)
                    }
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserWidget;
