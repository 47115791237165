const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const orderSchedules = (fetchedSchedule) => {
  const orderedSchedule = {};
  WEEK_DAYS.forEach((day) => {
    orderedSchedule[day] = fetchedSchedule[day] || [];
  });
  return JSON.stringify(orderedSchedule);
};
