import { Paper, Typography } from "@mui/material";
import React from "react";
import { customColors } from "../../assets/styles/Theme";

const UserOptionsCard = ({ text, icon, onSelect }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "10px",
        paddingY: "0.5rem",
        paddingX: "1.3rem",
        marginY: "0.5rem",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        gap: 2,
        width: { md: "30%", xs: "100%", sm: "45%" },
      }}
      elevation={4}
      onClick={onSelect}
    >
      {icon}
      <Typography variant="h6" sx={{ color: customColors.color_gray }}>
        {text}
      </Typography>
    </Paper>
  );
};

export default UserOptionsCard;
