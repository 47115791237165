import { ChevronLeft, LogoutOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import AuthNavbar from "../menu/AuthNavbar";
import SidebarComponent from "../menu/SidebarComponent";
import BottomNavigator from "./BottomNavigator";
import { minimizeText } from "../../utils/others/text.utils";
import { grey } from "@mui/material/colors";
import { AuthContext } from "../../contexts/Auth.context";
import { CustomOutlinedButton } from "../others/CustomButton";
import { signOut } from "firebase/auth";
import { auth } from "../../utils/firebase/firebaseConfig";
import { toast } from "react-toastify";

const SidebarWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const { showBottomNavigator, topLabel, collapsed } =
    useContext(ActiveSidebarContext);

  const [logoutLoading, setLogoutLoading] = useState(false);
  const chatScreens =
    window.location.href.includes("/appointment-chat/") ||
    window.location.href.includes("/doctors-chat/");
  const isOnXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Sign out user
  const handleSignOut = () => {
    setLogoutLoading(true);
    signOut(auth)
      .then(() => {
        toast.success("Signed out successfully");
        localStorage.removeItem("uid");
        navigate("/signin");
        setLogoutLoading(false);
      })
      .catch((err) => {
        setLogoutLoading(false);
        toast.error(err?.message);
      });
  };

  return (
    <>
      <AuthNavbar />
      <Box
        sx={{
          height: chatScreens ? "max-content" : "calc(max-content + 10vmax)",
          display: "flex",
          flexGrow: 1,
        }}
      >
        <SidebarComponent />
        <Box
          sx={{
            width: { xs: "100%", md: collapsed ? "95%" : "83.6%" },
            paddingX: { xs: "0.9rem", md: "1rem" },
            paddingTop: { xs: "1rem", md: "0rem" },
            textAlign: "left",
            marginBottom: chatScreens ? "0rem" : "5rem",
            backgroundColor: chatScreens ? grey[200] : customColors.color_white,
          }}
        >
          {!(
            (userData?.role === "admin" || userData?.role === "hospital") &&
            isOnXsScreen
          ) && (
            <>
              {!showBottomNavigator ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 3,
                    marginBottom: 1,
                  }}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      ml: "0.1rem",
                      display: { md: "none", sm: "block", xs: "block" },
                      backgroundColor: customColors.color_white,
                      border: `1px solid ${customColors.color_gray}`,
                      ":hover": {
                        backgroundColor: customColors.color_light_gray,
                      },
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      color: customColors.color_gray,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft />
                  </IconButton>

                  <Typography
                    variant="h5"
                    sx={{
                      my: "0.3rem",
                      color: customColors.color_primary,
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {minimizeText(topLabel)}
                  </Typography>
                </Box>
              ) : (
                <BottomNavigator />
              )}
            </>
          )}
          {userData?.isBlocked ? (
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              You've been blocked by System Administrators, Please contact{" "}
              <Typography
                component="a"
                href="mailto:wisedoctor@itscholding.com"
                sx={{
                  color: customColors.color_secondary,
                  fontStyle: "italic",
                }}
              >
                wisedoctor@itscholding.com
              </Typography>{" "}
              or call{" "}
              <Typography sx={{ color: customColors.color_secondary }}>
                +961 3 984 517.
              </Typography>
            </Typography>
          ) : (
            <>
              {(userData?.role === "admin" || userData?.role === "hospital") &&
              isOnXsScreen ? (
                <Box
                  sx={{
                    height: "85vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                    padding: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: customColors.color_red, my: "2rem" }}
                  >
                    This page is optimized for larger screens. Please access
                    this content using a desktop, laptop, or tablet for the best
                    experience.
                  </Typography>
                  <CustomOutlinedButton
                    color={customColors.color_primary}
                    loading={logoutLoading}
                    disabled={logoutLoading}
                    endIcon={<LogoutOutlined />}
                    onClick={handleSignOut}
                    label="Logout"
                  />
                </Box>
              ) : (
                <>{children}</>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SidebarWrapper;
