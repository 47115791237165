import React from "react";
import { customColors } from "../../assets/styles/Theme";
import { Paper, Typography } from "@mui/material";

const JoinedHospitalCard = ({ loading, data }) => {
  return (
    <Paper
      sx={{
        borderRadius: "10px",
        paddingY: "0.5rem",
        paddingX: "1.3rem",
        marginY: "0.5rem",
        backgroundColor: loading
          ? customColors.color_light_gray
          : customColors.color_white,
        cursor: loading ? "no-drop" : "pointer",
        gap: 2,
        width: { md: "30%", xs: "100%", sm: "45%" },
      }}
      elevation={4}
    >
      <Typography
        variant="h6"
        textTransform={"capitalize"}
        sx={{ color: customColors.color_gray }}
      >
        {data?.name}
      </Typography>
      <Typography variant="body2" sx={{ color: customColors.color_gray }}>
        Email: <span style={{ fontWeight: "bold" }}>{data?.email}</span>
      </Typography>
      <Typography variant="body2" sx={{ color: customColors.color_gray }}>
        Phone Number:{" "}
        <span style={{ fontWeight: "bold" }}>
          {data?.phoneNumber ? data.phoneNumber : "Not Available"}
        </span>
      </Typography>
      <Typography variant="subtitle2" sx={{ color: customColors.color_gray }}>
        Doctors:{" "}
        <span style={{ fontWeight: "bold" }}>{data?.doctorsCount}</span>
      </Typography>
      <Typography variant="subtitle2" sx={{ color: customColors.color_gray }}>
        Nurses: <span style={{ fontWeight: "bold" }}>{data?.nursesCount}</span>
      </Typography>
      <Typography variant="subtitle2" sx={{ color: customColors.color_gray }}>
        Patients:{" "}
        <span style={{ fontWeight: "bold" }}>{data?.patientsCount}</span>
      </Typography>
    </Paper>
  );
};

export default JoinedHospitalCard;
