import { Card, CardActionArea, Typography } from "@mui/material";
import React, { cloneElement } from "react";
import { customColors } from "../../assets/styles/Theme";

const CategoryCard = ({ category, isSelected, onPress }) => {
  const coloredIcon = cloneElement(category.icon, {
    sx: {
      color: isSelected ? customColors.color_white : customColors.color_primary,
      fontSize: "3.5rem",
    },
  });
  return (
    <Card
      sx={{
        border: `1.5px solid ${customColors.color_primary}`,
        backgroundColor: isSelected
          ? customColors.color_primary
          : customColors.color_white,
        color: isSelected
          ? customColors.color_white
          : customColors.color_primary,
        width: "7rem",
        height: "7rem",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "8px",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
      onClick={onPress}
    >
      <CardActionArea
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {coloredIcon}
        <Typography
          variant="h6"
          textTransform="capitalize"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: isSelected
              ? customColors.color_white
              : customColors.color_primary,
          }}
        >
          {category.name}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
