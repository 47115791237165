import { Paper, Typography } from "@mui/material";
import React from "react";
import { customColors } from "../../assets/styles/Theme";

const UserHospitalCard = ({ text, onSelect, disabled }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "10px",
        paddingY: "0.5rem",
        paddingX: "1.3rem",
        marginY: "0.5rem",
        backgroundColor: disabled
          ? customColors.color_light_gray
          : customColors.color_white,
        cursor: disabled ? "no-drop" : "pointer",
        gap: 2,
        width: { md: "30%", xs: "100%", sm: "45%" },
      }}
      elevation={4}
      onClick={onSelect}
    >
      <Typography
        variant="h6"
        textTransform={"capitalize"}
        sx={{ color: customColors.color_gray }}
      >
        {text}
      </Typography>
    </Paper>
  );
};

export default UserHospitalCard;
