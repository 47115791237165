import Rating from "@mui/material/Rating";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase/firebaseConfig";

const DisplayServiceRating = ({ serviceId }) => {
  const [avgRating, setAvgRating] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (serviceId) {
      getAvgRating();
    } else {
      return null;
    }
  }, [serviceId]);

  const getAvgRating = async () => {
    try {
      const ratingQuery = query(
        collection(db, "userAppointments"),
        where("ratings", ">", 0)
      );

      const snapshot = await getDocs(ratingQuery);

      if (!snapshot.empty) {
        const { totalRatings, ratingCount } = snapshot.docs.reduce(
          (acc, doc) => {
            const rating = doc.data().ratings;
            acc.totalRatings += rating;
            acc.ratingCount += 1;
            return acc;
          },
          { totalRatings: 0, ratingCount: 0 }
        );

        // Check if we have any ratings before calculating the average
        if (ratingCount > 0) {
          const averageRating = totalRatings / ratingCount;
          setAvgRating(averageRating);
        } else {
          console.log("No ratings found.");
        }
      }
    } catch (error) {
      console.log("Error while getting average rating:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>{!loading && <Rating value={avgRating} readOnly precision={0.5} />}</>
  );
};

export default DisplayServiceRating;
