import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { doc, getDoc } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebFont from "webfontloader";
import "./App.css";
import ActiveSidebarContextProvider from "./contexts/ActiveSidebarContext";
import { AuthContext } from "./contexts/Auth.context";
import SignupContextProvider from "./contexts/SignUp.context";
import ForgotPasswordPage from "./pages/auth/ForgotPassword.page";
import SignInPage from "./pages/auth/SignIn.page";
import SignUpPage from "./pages/auth/SignUp.page";
import Home from "./pages/home/Home";
import PrivacyPolicy from "./pages/home/PrivacyPolicy";
import AppointmentsPage from "./pages/main/AppointmentsPage";
import Dashboard from "./pages/main/Dashboard";
import MapPage from "./pages/main/MapPage";
import SearchPage from "./pages/main/SearchPage";
import UserOptionsPage from "./pages/main/UserOptionsPage";
import ProfilePage from "./pages/profile/ProfilePage";
import { db } from "./utils/firebase/firebaseConfig";
import ServiceBookingPage from "./pages/service/ServiceBookingPage";
import NotFoundPage from "./pages/others/NotFoundPage";
import BookingDetailsPage from "./pages/service/BookingDetailsPage";
import BookingHistoryPage from "./pages/service/BookingHistoryPage";
import SchedulePage from "./pages/service/SchedulePage";
import AppointmentMessages from "./pages/messages/AppointmentMessages";
import DoctorMessages from "./pages/messages/DoctorMessages";
import ChatRoomPage from "./pages/messages/ChatRoomPage";
import CustomerSupportPage from "./pages/messages/CustomerSupportPage";
import Hospitals from "./pages/admin/Hospitals";
import AllDoctors from "./pages/hospitals/AllDoctors";
import AllNurses from "./pages/hospitals/AllNurses";
import AllPatients from "./pages/hospitals/AllPatients";
import Admins from "./pages/admin/Admins";
import JoinHospital from "./pages/profile/JoinHospital";
import HospitalAcceptedUsers from "./pages/hospitals/HospitalAcceptedUsers";
import HospitalPendingUsers from "./pages/hospitals/HospitalPendingUsers";
import MyHospitals from "./pages/profile/MyHospitals";
import DeveloperPaymentPage from "./pages/home/DeveloperPaymentPage";

const theme = createTheme();

function App() {
  const { setUserData } = useContext(AuthContext);
  const DEVELOPER_PAID = true;
  const checkFunc = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const currentData = userDoc.data();
        setUserData({ uid, ...currentData });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Rockwell", "Roboto", "Poppins"],
      },
    });

    if (localStorage.getItem("uid")) {
      checkFunc(localStorage.getItem("uid"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ActiveSidebarContextProvider>
        <SignupContextProvider>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
          <CssBaseline />
          {DEVELOPER_PAID ? (
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/signin" element={<SignInPage />} />
              <Route exact path="/signup" element={<SignUpPage />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPasswordPage />}
              />

              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/search" element={<SearchPage />} />
              <Route exact path="/map" element={<MapPage />} />
              <Route
                exact
                path="/appointments"
                element={<AppointmentsPage />}
              />
              <Route exact path="/user" element={<UserOptionsPage />} />

              <Route exact path="/profile" element={<ProfilePage />} />
              <Route
                exact
                path="/service-booking/:serviceId"
                element={<ServiceBookingPage />}
              />
              <Route
                exact
                path="/appointment/:appointmentId"
                element={<BookingDetailsPage />}
              />
              <Route
                exact
                path="/booking-history"
                element={<BookingHistoryPage />}
              />
              <Route exact path="/schedule" element={<SchedulePage />} />
              <Route
                exact
                path="/appointment-chat/:appointmentId/:receiverId"
                element={<AppointmentMessages />}
              />
              <Route
                exact
                path="/doctors-chat/:receiver"
                element={<DoctorMessages />}
              />
              <Route exact path="/chat-room" element={<ChatRoomPage />} />
              <Route exact path="/join-hospital" element={<JoinHospital />} />
              <Route exact path="/my-hospitals" element={<MyHospitals />} />
              <Route exact path="/support" element={<CustomerSupportPage />} />

              <Route exact path="/all-doctors" element={<AllDoctors />} />
              <Route exact path="/all-nurses" element={<AllNurses />} />
              <Route exact path="/all-patients" element={<AllPatients />} />
              <Route
                exact
                path="/:hospitalId/accepted"
                element={<HospitalAcceptedUsers />}
              />
              <Route
                exact
                path="/:hospitalId/pending"
                element={<HospitalPendingUsers />}
              />

              <Route exact path="/admin/hospitals" element={<Hospitals />} />
              <Route exact path="/admin/admins" element={<Admins />} />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/" element={<DeveloperPaymentPage />} />
              <Route path="*" element={<DeveloperPaymentPage />} />
            </Routes>
          )}
        </SignupContextProvider>
      </ActiveSidebarContextProvider>
    </ThemeProvider>
  );
}

export default App;
