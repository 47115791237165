import { Box } from "@mui/material";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UserHospitalCard from "../../components/cards/UserHospitalCard";
import { SmallLoader } from "../../components/loading/Loader";
import SearchBar from "../../components/others/SearchBar";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { getAllHospitals } from "../../utils/functions/hospitals";

const JoinHospital = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [joinHospitalLoading, setJoinHospitalLoading] = useState(false);

  useEffect(() => {
    getHospitals();
    setShowBottomNavigator(false);
    setTopLabel("Join Hospital");
    setActiveSidebarText("profile");
  }, [setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  const searchHandler = useCallback(
    (text) => {
      const searchWord = text.trim().toLowerCase();
      if (searchWord === "") {
        setFilteredData(hospitals);
      } else {
        for (const hospital of hospitals) {
          const newArr = [];
          if (hospital?.name?.includes(searchWord)) {
            newArr.push(hospital);
          }
          setFilteredData(newArr);
        }
      }
    },
    [hospitals]
  );

  const searchTexthandler = (text) => {
    setSearchText(text);
  };

  useEffect(() => {
    searchHandler(searchText);
  }, [hospitals, searchHandler, searchText]);

  const getHospitals = async () => {
    try {
      setLoading(true);
      const { hospitalsData } = await getAllHospitals();
      setHospitals(hospitalsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleHospitalJoin = async (hospital) => {
    try {
      setJoinHospitalLoading(true);
      const hospitalRef = doc(db, "hospitals", hospital.id); // Reference to the hospital document
      const hospitalSnapshot = await getDoc(hospitalRef);

      const userId = userData.uid; // Get the userId from your context (userData)

      // Object to add to hospital document
      const userObject = {
        userId,
        role: userData.role || "", // Set role from userData or set default
        accepted: false, // Default to false, modify as needed
        joiningTime: Date.now(),
        left: false,
        leftTime: null, // Null since the user has not left yet
        leavingReason: "",
      };

      if (hospitalSnapshot.exists()) {
        const hospitalData = hospitalSnapshot.data();
        const users = hospitalData.users || [];

        // Check if the user has already joined the hospital
        const alreadyJoined = users.some((user) => user.userId === userId);

        if (alreadyJoined) {
          toast.info(
            "You have already joined " +
              hospital.name +
              `${
                !alreadyJoined.accepted ? ", Your request is in progress." : "."
              }`
          );
        } else {
          // Add the new user if they haven't joined yet
          await updateDoc(hospitalRef, {
            users: arrayUnion(userObject), // Add the new user to the array
          });
          toast.success(`Request sent to ${hospital.name}`);
        }
      } else {
        // If no document exists, create a new one
        await setDoc(hospitalRef, {
          users: [userObject], // Initialize with the user object inside an array
        });
        toast.success(`Successfully joined ${hospital.name}`);
      }
    } catch (error) {
      console.error("Error joining hospital:", error);
      toast.error("Failed to join hospital. Please try again later.");
    } finally {
      setJoinHospitalLoading(false);
    }
  };

  return (
    <SidebarWrapper>
      <SearchBar placeholder="Search Hospital" onSearch={searchTexthandler} />
      {loading ? (
        <SmallLoader />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            my: "1rem",
          }}
        >
          {filteredData.map((hospital, index) => (
            <UserHospitalCard
              key={index}
              text={hospital?.name}
              onSelect={() => handleHospitalJoin(hospital)}
              disabled={joinHospitalLoading}
            />
          ))}
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default JoinHospital;
