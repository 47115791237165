import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputBase, Paper } from "@mui/material";
import React from "react";
import { customColors } from "../../assets/styles/Theme";

const SearchBar = ({ onSearch, placeholder }) => {

  return (
    <Paper
      elevation={6}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: customColors.color_white,
        padding: "4px 12px",
        borderRadius: "20px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <IconButton sx={{ padding: "8px" }} aria-label="search">
        <SearchIcon sx={{ color: customColors.color_gray }} />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onChange={(event) => onSearch(event.target.value)}
        inputProps={{ "aria-label": placeholder }}
        style={{
          color: customColors.color_gray,
        }}
      />
    </Paper>
  );
};

export default SearchBar;
