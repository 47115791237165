import { Typography } from "@mui/material";
import React from "react";
import { customColors } from "../../assets/styles/Theme";

const TimeSlot = ({ time, onClick, isSelected, isBooked }) => {
  const handlePress = () => {
    if (!isBooked) {
      onClick(time.apptime);
    }
  };

  return (
    <Typography
      variant={isSelected ? "contained" : "outlined"}
      color={customColors.color_primary}
      className={`time-slot ${isSelected ? "selected" : ""} ${
        isBooked ? "booked" : ""
      }`}
      onClick={handlePress}
      disabled={isBooked}
      sx={{
        borderRadius: "15px",
        cursor: "pointer",
        padding: "0.5rem 0.7rem",
        border: `1px solid ${
          isSelected ? customColors.color_primary : customColors.color_gray
        }`,
        color: isSelected ? customColors.color_white : customColors.color_gray,
        backgroundColor: isSelected
          ? customColors.color_primary
          : customColors.color_white,
        m: 1,
      }}
    >
      {time.apptime}
    </Typography>
  );
};

export default TimeSlot;
