import { LoadingButton } from "@mui/lab";
import React from "react";
import { customColors } from "../../assets/styles/Theme";

const CustomButton = ({ label, loading, disabled, onClick }) => {
  return (
    <LoadingButton
      sx={{
        my: "1rem",
        width: { xs: "100%", md: "30%" },
        backgroundColor: disabled
          ? customColors.color_light_gray
          : customColors.color_primary,
        color: customColors.color_white,
        borderRadius: "1rem",
        ":hover": { backgroundColor: customColors.color_primary },
      }}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </LoadingButton>
  );
};

export const CustomOutlinedButton = ({
  label,
  loading,
  disabled,
  onClick,
  color,
  disabledColor,
  endIcon,
}) => {
  return (
    <LoadingButton
      endIcon={endIcon || null}
      sx={{
        my: "1rem",
        width: { xs: "100%", md: "30%" },
        borderColor: disabled
          ? disabled
            ? disabledColor
            : customColors.color_light_gray
          : color
          ? color
          : customColors.color_primary,
        color: color || customColors.color_primary,
        borderRadius: "1rem",
      }}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
    >
      {label}
    </LoadingButton>
  );
};

export default CustomButton;
