import { Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useContext, useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import userpin from "../../assets/auth/userpin.png";
import doctorpin from "../../assets/auth/doctorpin.png";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";

const userLocationPin = new Icon({
  iconUrl: userpin,
  iconSize: [40, 40],
});

const doctorLocationPin = new Icon({
  iconUrl: doctorpin,
  iconSize: [35, 35],
});

const MapPage = () => {
  const { userData, fetchUserData } = useContext(AuthContext);
  const { setShowBottomNavigator, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [defaultPosition, setDefaultPosition] = useState([33.8938, 35.5018]);
  const [userHasLocation, setUserHasLocation] = useState(false);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setShowBottomNavigator(true);
    setActiveSidebarText("map");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator]);

  useEffect(() => {
    if (userData) {
      const lat = userData?.location_coords?.latitude;
      const lng = userData?.location_coords?.longitude;

      if (lat && lng) {
        setDefaultPosition([lat, lng]);
        setUserHasLocation(true);
      }
    }
  }, [userData]);

  // Fetch the doctor/nurse data on component mount
  useEffect(() => {
    fetchDocNurse();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch doctors and nurses from Firestore and update the serviceList
  const fetchDocNurse = async () => {
    try {
      setLoading(true); // Set loading state to true while fetching
      const doctorsQuery = query(
        collection(db, "users"), // Assuming 'users' is your collection name
        where("role", "in", ["doctor", "nurse"]) // Fetch both doctors and nurses
      );

      const querySnapshot = await getDocs(doctorsQuery); // Fetch the query results
      const docNurseRes = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.email !== userData?.email) {
          const location_coords = data.location_coords;

          if (location_coords) {
            docNurseRes.push({
              id: doc.id,
              ...data,
              latitude: location_coords.latitude,
              longitude: location_coords.longitude,
            });
          }
        }
      });

      setServiceList(docNurseRes); // Set the serviceList with doctors and nurses, including coordinates
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching doctors and nurses:", error);
    }
  };
  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <MapContainer
          center={defaultPosition}
          zoom={7.3}
          scrollWheelZoom={true}
          style={{
            height: "calc(100vh - 4rem)",
            width: "100%",
            borderRadius: "1rem",
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://wise-doctor.com/">Wise Doctor</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {userHasLocation && (
            <Marker icon={userLocationPin} position={defaultPosition}>
              <Popup>
                <Typography variant="h6">Your Location</Typography>
                <Typography>{userData?.location}</Typography>
              </Popup>
            </Marker>
          )}
          {serviceList.map((service, index) => (
            <Marker
              icon={doctorLocationPin}
              position={[service.latitude, service.longitude]}
              key={index}
            >
              <Popup>
                <Typography variant="h6">
                  {service?.role === "doctor" &&
                    !service?.firstName?.toLowerCase()?.startsWith("dr.") &&
                    "Dr. "}
                  {service?.firstName} {service?.lastName} (
                  {service?.specialization})
                </Typography>
                <p>📍 {service?.location}</p>
                {(userData?.role === "patient" ||
                  userData?.role === "doctor" ||
                  userData?.role === "nurse") && (
                  <Link
                    to={
                      userData?.role === "patient"
                        ? `/service-booking/${service.id}?type=map`
                        : `/doctors-chat/${service.id}`
                    }
                    style={{
                      color: customColors.color_primary,
                      textDecoration: "none",
                      fontSize: "1.3rem",
                    }}
                  >
                    {userData?.role === "patient"
                      ? "Book an Appointment"
                      : "Chat"}
                  </Link>
                )}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}
    </SidebarWrapper>
  );
};

export default MapPage;
