import { ArrowBackOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Paper, TextField, Typography } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import CustomButton from "../../components/others/CustomButton";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handlePasswordReset = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage("");

    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage(
        `An email with a password reset link has been sent to ${email}. Please check your inbox.`
      );
    } catch (err) {
      setError(
        "Failed to send password reset email. Please check the email address and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: "2rem", maxWidth: "50vw", margin: "2rem auto" }}
    >
      <Typography variant="h5" gutterBottom>
        Forgot Password
      </Typography>
      <Typography variant="body1" gutterBottom>
        Enter your email address to receive a password reset link.
      </Typography>

      {successMessage && (
        <Alert severity="success" sx={{ marginBottom: "1rem" }}>
          {successMessage}
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ marginBottom: "1rem" }}>
          {error}
        </Alert>
      )}

      <TextField
        label="Email Address"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: "1rem" }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
      />
      <CustomButton
        onClick={handlePasswordReset}
        loading={loading}
        disabled={loading || !email}
        label="Send Reset Link"
      />
      <br />
      <LoadingButton
        variant="outlined"
        startIcon={<ArrowBackOutlined />}
        onClick={() => navigate(-1)}
        sx={{
          textTransform: "none",
          borderColor: customColors.color_primary,
          width: { xs: "100%", md: "30%" },
          color: customColors.color_primary,
          marginBottom: "1rem",
        }}
      >
        Back
      </LoadingButton>
    </Paper>
  );
};

export default ForgotPasswordPage;
