import { Avatar, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { HospitalPage } from "../../utils/others/AccessController";
import { AuthContext } from "../../contexts/Auth.context";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { getAcceptedUsersAndRows } from "../../utils/functions/hospitals";
import { LoadingButton } from "@mui/lab";
import { customColors } from "../../assets/styles/Theme";
import CustomModal from "../../components/others/CustomModal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const HospitalAcceptedUsers = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [doctorRows, setDoctorRows] = useState([]);
  const [nurseRows, setNurseRows] = useState([]);
  const [patientRows, setPatientRows] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setActiveSidebarText("hospital-all-accepted");
    setTopLabel("Accepted Users");
    getAcceptedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator, setActiveSidebarText, setTopLabel]);

  const getAcceptedUsers = async () => {
    try {
      setLoading(true);
      const { acceptedDoctorsRows, acceptedNursesRows, acceptedPatientsRows } =
        await getAcceptedUsersAndRows(userData.uid);
      setDoctorRows(acceptedDoctorsRows);
      setNurseRows(acceptedNursesRows);
      setPatientRows(acceptedPatientsRows);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const doctorColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "specialization", headerName: "Specialization", flex: 0.5 },
    { field: "qualification", headerName: "Qualification", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    { field: "schedules", headerName: "Schedules", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id, "doctor")}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const nurseColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "specialization", headerName: "Specialization", flex: 0.5 },
    { field: "qualification", headerName: "Qualification", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    { field: "schedules", headerName: "Schedules", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id, "nurse")}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const patientColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "treatment", headerName: "Treatment", flex: 0.5 },
    { field: "meds", headerName: "Medications", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    { field: "schedules", headerName: "Schedules", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id, "patient")}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const showModal = (id, role) => {
    let newArr = [];
    if (role === "patient") {
      const currentData = patientRows.find((item) => item.id === id);
      newArr = [
        {
          key1: "ID",
          value1: id,
        },
        {
          key1: "First Name",
          value1: currentData.firstName,
        },
        {
          key1: "Last Name",
          value1: currentData.lastName,
        },
        {
          key1: "Medications",
          value1: currentData.meds,
        },
        {
          key1: "Treatment",
          value1: currentData.treatment,
        },
        {
          key1: "Location",
          value1: currentData.location || "Not Available",
        },
        {
          key1: "Phone Number",
          value1: currentData.phoneNumber,
        },
        {
          key1: "Appointments",
          value1: currentData.appointments,
        },
      ];
    } else if (role === "doctor") {
      const currentData = doctorRows.find((item) => item.id === id);
      newArr = [
        {
          key1: "ID",
          value1: id,
        },
        {
          key1: "First Name",
          value1: currentData.firstName,
        },
        {
          key1: "Last Name",
          value1: currentData.lastName,
        },
        {
          key1: "Specialization",
          value1: currentData.specialization,
        },
        {
          key1: "Qualification",
          value1: currentData.qualification,
        },
        {
          key1: "Location",
          value1: currentData.location || "Not Available",
        },
        {
          key1: "Phone Number",
          value1: currentData.phoneNumber,
        },
        {
          key1: "Appointments",
          value1: currentData.appointments,
        },
      ];
    } else if (role === "nurse") {
      const currentData = nurseRows.find((item) => item.id === id);
      newArr = [
        {
          key1: "ID",
          value1: id,
        },
        {
          key1: "First Name",
          value1: currentData.firstName,
        },
        {
          key1: "Last Name",
          value1: currentData.lastName,
        },
        {
          key1: "Specialization",
          value1: currentData.specialization,
        },
        {
          key1: "Qualification",
          value1: currentData.qualification,
        },
        {
          key1: "Location",
          value1: currentData.location || "Not Available",
        },
        {
          key1: "Phone Number",
          value1: currentData.phoneNumber,
        },
        {
          key1: "Appointments",
          value1: currentData.appointments,
        },
      ];
    }
    setModalData(newArr);
    setOpenModal(true);
  };

  return (
    <HospitalPage>
      <SidebarWrapper>
        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary, my: "0.5rem" }}
          >
            Accepted Doctors
          </Typography>
          {doctorRows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Accepted Doctors to Approve!
            </Typography>
          ) : (
            <DataGrid
              rows={doctorRows}
              columns={doctorColumns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
            my: "1rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary, my: "0.5rem" }}
          >
            Accepted Nurses
          </Typography>
          {nurseRows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Accepted Nurses to Approve!
            </Typography>
          ) : (
            <DataGrid
              rows={nurseRows}
              columns={nurseColumns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
            my: "1rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary, my: "0.5rem" }}
          >
            Accepted Patients
          </Typography>
          {patientRows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Accepted Patients to Approve!
            </Typography>
          ) : (
            <DataGrid
              rows={patientRows}
              columns={patientColumns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        {openModal && (
          <CustomModal
            data={modalData}
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}
      </SidebarWrapper>
    </HospitalPage>
  );
};

export default HospitalAcceptedUsers;
