import { Avatar, Box, Paper, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../utils/firebase/firebaseConfig";

const UserChatCard = ({ senderId }) => {
  const navigate = useNavigate();
  const [senderData, setSenderData] = useState(null);
  const [deletedAccount, setDeletedAccount] = useState(false);

  useEffect(() => {
    const fetchSenderData = async () => {
      try {
        const senderRef = doc(db, "users", senderId);
        const senderSnapshot = await getDoc(senderRef);
        if (senderSnapshot.exists()) {
          setSenderData(senderSnapshot.data());
        }
      } catch (error) {
        console.error("Error fetching sender data:", error);
        setDeletedAccount(true);
      }
    };

    fetchSenderData();
  }, [senderId]);

  return (
    senderData && (
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          marginBottom: "0.7rem",
          padding: "1rem",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/doctors-chat/${senderId}`)}
      >
        <Avatar
          src={senderData?.avatar}
          alt={senderData.firstName}
          sx={{ width: 70, height: 70 }}
        />

        {deletedAccount ? (
          <Typography variant="h6" color="error">
            Deleted Account
          </Typography>
        ) : (
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {senderData?.role === "doctor" &&
                !senderData?.firstName?.toLowerCase()?.startsWith("dr.") &&
                "Dr. "}
              {senderData?.firstName} {senderData?.lastName}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >{`Tap to chat with ${senderData?.firstName}`}</Typography>
          </Box>
        )}
      </Paper>
    )
  );
};

export default UserChatCard;
