import { doc, getDoc } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../utils/firebase/firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Loader from "../components/loading/Loader";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Start loading as true
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  const isPublic =
    window.location.href.endsWith("/") ||
    window.location.href.endsWith("/signin") ||
    window.location.href.endsWith("/signup") ||
    window.location.href.endsWith("/privacy-policy") ||
    window.location.href.endsWith("/forgot-password");

  useEffect(() => {
    if (!loading) {
      if (!loggedIn && !isPublic && !userData) {
        navigate("/");
      }
    }
  }, [isPublic, loading, loggedIn, userData, navigate]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true); // Set loading to true when checking auth state
      if (user) {
        setLoggedIn(true);
        localStorage.setItem("uid", user.uid);
        await fetchUserData(user.uid); // Fetch user data by UID
      } else {
        setLoggedIn(false);
        setUserData(null);
      }
      setLoading(false); // Set loading to false once the user is checked
    });

    return () => unsubscribe();
  }, []);

  // Fetch user data from Firestore
  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const currentData = userDoc.data();
        setUserData({ uid, ...currentData });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  if (loading) {
    return <Loader />; // Show loading component while loading
  }

  return (
    <AuthContext.Provider
      value={{ userData, setUserData, loggedIn, setLoggedIn, fetchUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
