import { SendOutlined } from "@mui/icons-material";
import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { formatChatTime } from "../../utils/others/calendar.utils";

const AppointmentMessages = () => {
  const navigate = useNavigate();
  const { appointmentId, receiverId } = useParams();

  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [appointmentData, setAppointmentData] = useState(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Appointment Chat");
    getAppointmentDetails(true);
    getReceiverData();
    setActiveSidebarText("appointments");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator, setTopLabel]);

  useEffect(() => {
    const intervalId = setInterval(fetchMessages, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMessages = () => {
    if (!sending) {
      getAppointmentDetails(false);
    }
  };
  const getReceiverData = async () => {
    try {
      const receiverDoc = await getDoc(doc(db, "users", receiverId));
      if (receiverDoc.exists()) {
        const receiverData = receiverDoc.data();
        setTopLabel(
          receiverData?.role === "doctor" &&
            !receiverData?.firstName?.toLowerCase()?.startsWith("dr.")
            ? `Dr. ${receiverData?.firstName}`
            : receiverData?.firstName
        );
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };
  const getAppointmentDetails = async (shouldLoad) => {
    if (shouldLoad) {
      setLoading(true);
    }
    const appointmentDoc = await getDoc(
      doc(db, "userAppointments", appointmentId)
    );
    if (appointmentDoc.exists()) {
      const appointment = appointmentDoc.data();
      setAppointmentData({ id: appointmentId, ...appointment });
    } else {
      navigate(-1);
      toast.error("No Appointment foind!");
    }
    setLoading(false);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (receiverId && message.trim() !== "") {
        setSending(true);
        const newMessageObj = {
          message,
          receiverId,
          senderId: userData.uid,
          isPatient: userData.role === "patient",
          new: true,
          timestamp: Date.now(),
        };
        const docRef = doc(db, "userAppointments", appointmentId);

        // Update the 'messages' field by appending the new message
        await updateDoc(docRef, {
          messages: arrayUnion(newMessageObj),
        });

        setMessage(""); // Clear the input

        // Retrieve the updated document to get the latest messages
        const newAppointmentSnapshot = await getDoc(docRef);

        // Ensure the document exists before accessing messages
        if (newAppointmentSnapshot.exists()) {
          const updatedAppointmentDoc = newAppointmentSnapshot.data();
          setAppointmentData(updatedAppointmentDoc);
        } else {
          console.error("Document not found.");
        }

        setSending(false);
      }
    } catch (error) {
      toast.error("An error occurred while sending the message.");
      console.error(error);
      setSending(false);
    }
  };

  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: { xs: "90vh", md: "80vh" },
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              margin: "16px",
            }}
          >
            {appointmentData?.messages &&
              appointmentData.messages.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent:
                      item.senderId === userData.uid
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <Paper
                    key={index}
                    sx={{
                      backgroundColor:
                        item.senderId === userData.uid
                          ? customColors.color_primary
                          : customColors.color_white,
                      color:
                        item.senderId === userData.uid
                          ? customColors.color_white
                          : customColors.color_gray,
                      padding: 1,
                      width: "auto",
                      maxWidth: "60%",
                      borderRadius: "0.3rem",
                      marginY: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color:
                          item.senderId === userData.uid
                            ? customColors.color_white
                            : customColors.color_gray,
                        fontWeight: 500,
                      }}
                    >
                      {item.message}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 9,
                        textAlign: "right",
                        color: customColors.color_gray,
                      }}
                    >
                      {formatChatTime(item.timestamp)}
                    </Typography>
                  </Paper>
                </Box>
              ))}
          </Box>

          <Box
            component="form"
            onSubmit={sendMessage}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem 0.7rem",
              borderTop: "1px solid #dddddd",
              backgroundColor: "#fff",
              gap: 2,
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              sx={{ flex: 1 }}
            />
            <IconButton
              onClick={sendMessage}
              type="submit"
              disabled={sending}
              sx={{
                backgroundColor: customColors.color_primary,
                ":hover": { backgroundColor: customColors.color_primary },
              }}
            >
              <SendOutlined sx={{ color: customColors.color_white }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default AppointmentMessages;
