import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

export const getAllPatients = async () => {
  try {
    const doctorsQuery = query(
      collection(db, "users"),
      where("role", "==", "patient")
    );
    const querySnapshot = await getDocs(doctorsQuery);

    const patientsData = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const appointmentsQuery = query(
          collection(db, "userAppointments"),
          where("userId", "==", doc.id)
        );
        const aptmtquerySnapshot = await getDocs(appointmentsQuery);
        let appointments = aptmtquerySnapshot.size;
        return { id: doc.id, ...doc.data(), appointments };
      })
    );

    const patientRows = patientsData.map((d) => ({
      id: d.id,
      firstName: d.firstName,
      lastName: d.lastName,
      meds: d.meds,
      treatment: d.treatment,
      location: d.location,
      phoneNumber: d.phoneNumber,
      appointments: d.appointments,
      avatar: d.avatar,
    }));

    return { patientsData, patientRows };
  } catch (error) {
    console.error("Error fetching doctors:", error);
    throw new Error(error.message);
  }
};
