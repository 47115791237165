import React, { useContext, useState } from "react";
import { Box, TextField, Typography, Zoom, IconButton } from "@mui/material";
import { ChevronLeftOutlined, PhotoCamera } from "@mui/icons-material";
import { SignupContext } from "../../contexts/SignUp.context";
import { LoadingButton } from "@mui/lab";
import { customColors } from "../../assets/styles/Theme";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../utils/firebase/firebaseConfig";
import uploadImageToFirebase from "../../utils/firebase/uploadImageToFirebase";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DoctorAuthInput = ({ setShowNextComponent }) => {
  const navigate = useNavigate();
  const { firstName, lastName, email, phoneNumber, password, role } =
    useContext(SignupContext);
  const [photoURL, setPhotoURL] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [qualification, setQualification] = useState("");
  const [hospital, setHospital] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const currentUser = userCredential.user;

      // Upload avatar image if provided
      let downloadURL = "";
      if (photoURL?.length > 0) {
        downloadURL = await uploadImageToFirebase(photoURL);
      }

      // Create user document in Firestore
      await setDoc(doc(db, "users", currentUser.uid), {
        firstName,
        lastName,
        phoneNumber,
        email,
        role,
        avatar: downloadURL,
        meds: "",
        diseases: "",
        treatment: "",
        specialization,
        qualification,
        schedules: [],
      });

      toast.success("Signed Up successfully!");
    } catch (error) {
      console.log("Error in doctor sign up::", error);
      setError(error?.message);
      toast.error(error?.message);
    } finally {
      setLoading(false);
      navigate("/dashboard");
    }
  };

  return (
    <Box sx={{ minHeight: "100% !important", width: "100%" }}>
      <Zoom in={true} timeout={300} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              marginTop: { xs: "1rem", md: "0" },
              width: { xs: "90vw", md: "60vw" },
              gap: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{ color: customColors.color_primary, my: 2 }}
              textTransform="capitalize"
            >
              {role} Registration
            </Typography>

            <Box sx={{ gap: 2 }}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{
                    border: photoURL ? 0 : 2,
                    borderStyle: "dashed",
                    borderRadius: "50%",
                    height: 120,
                    width: 120,
                    margin: "auto",
                    overflow: "hidden",
                  }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  {photoURL ? (
                    <img
                      src={photoURL}
                      alt="Profile"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <PhotoCamera sx={{ fontSize: 60 }} />
                  )}
                </IconButton>
              </Box>
              <TextField
                fullWidth
                label="Specialization"
                required
                variant="outlined"
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
                sx={{ my: 1 }}
              />
              <TextField
                fullWidth
                required
                label="Qualification"
                variant="outlined"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
                sx={{ my: 1 }}
              />
              <TextField
                fullWidth
                label="Hospital"
                required
                variant="outlined"
                value={hospital}
                onChange={(e) => setHospital(e.target.value)}
                sx={{ my: 1 }}
              />
              {error?.length > 0 && (
                <Typography
                  variant="body1"
                  color="red"
                  align="center"
                  gutterBottom
                >
                  {error}
                </Typography>
              )}
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                sx={{
                  backgroundColor: customColors.color_primary,
                  "&:hover": {
                    backgroundColor: customColors.color_primary,
                  },
                  my: 1,
                }}
              >
                Register
              </LoadingButton>
              <LoadingButton
                startIcon={<ChevronLeftOutlined />}
                fullWidth
                variant="outlined"
                color={customColors.color_primary}
                onClick={() => setShowNextComponent(false)}
                sx={{
                  color: customColors.color_primary,
                  my: 1,
                }}
              >
                Back
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Zoom>
    </Box>
  );
};

export default DoctorAuthInput;
