import { Box, TextField, Typography } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../components/others/CustomButton";
import { AuthContext } from "../../contexts/Auth.context";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../../utils/firebase/firebaseConfig";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { customColors } from "../../assets/styles/Theme";

const CustomerSupportPage = () => {
  const navigate = useNavigate();

  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Support");
    setActiveSidebarText("profile");
  }, [setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  const handleSupportRequest = async (e) => {
    e.preventDefault();
    if (!subject || !description) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);

    try {
      // Add the support request to Firestore
      await addDoc(collection(db, "supportRequests"), {
        uid: userData.uid,
        subject,
        description,
        timestamp: new Date(),
        closed: false,
      });

      toast.success(
        "Your support request has been submitted. We'll get back to you soon."
      );
      setSubject("");
      setDescription("");
      navigate("/dashboard");
    } catch (error) {
      alert("Error submitting your request. Please try again.");
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SidebarWrapper>
      <Box
        component="form"
        onSubmit={handleSupportRequest}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <TextField
          label="Subject"
          variant="outlined"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <TextField
          label="Description"
          variant="outlined"
          multiline
          rows={5}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <CustomButton
          onClick={handleSupportRequest}
          loading={loading}
          disabled={loading}
          label="Submit Support Request"
        />

        <Typography
          variant="body2"
          sx={{ textAlign: "center" }}
        >
          For urgent support, contact us via email at{" "}
          <Typography
            component="a"
            href="mailto:wisedoctor@itscholding.com"
            sx={{ color: customColors.color_secondary, fontStyle: "italic" }}
          >
            wisedoctor@itscholding.com
          </Typography>{" "}
          or call{" "}
          <Typography sx={{ color: customColors.color_secondary }}>
            +961 3 984 517.
          </Typography>
        </Typography>
      </Box>
    </SidebarWrapper>
  );
};

export default CustomerSupportPage;
