import {
    Box,
    TextField,
    Typography
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import { db } from "../../utils/firebase/firebaseConfig";
import CustomButton from "../others/CustomButton";

const SubmitAppointmentReview = ({ appointmentData, onCompleteFunc }) => {
  const [ratings, setRatings] = useState(0);
  const [reviews, setReviews] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!appointmentData) {
      return null;
    }
  }, [appointmentData]);

  const submitAppointmentHandler = async () => {
    try {
      setSubmitting(true);
      if (ratings <= 0) {
        alert(
          "Please provide a rating to proceed with submitting your review."
        );
        setSubmitting(false);
        return;
      }
      await updateDoc(doc(db, "userAppointments", appointmentData.id), {
        ratings,
        reviews,
      });
      setSubmitting(false);
      if (onCompleteFunc) {
        onCompleteFunc();
      }
    } catch (error) {
      console.log("Error while submitting review:", error);
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: customColors.color_white,
        padding: 2,
        borderRadius: 2,
        boxShadow: 1,
        maxWidth: 500,
        margin: "0 auto",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Rate Your Appointment
      </Typography>
      <Rating
        name="appointment-rating"
        value={ratings}
        onChange={(event, newValue) => setRatings(newValue)}
        precision={0.5}
      />
      <TextField
        variant="outlined"
        multiline
        rows={5}
        value={reviews}
        onChange={(event) => setReviews(event.target.value)}
        placeholder="Submit a review (optional)"
        sx={{
          width: "100%",
          marginTop: 2,
          borderColor: customColors.color_gray,
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
          },
        }}
      />

      <CustomButton
        onClick={submitAppointmentHandler}
        disabled={submitting}
        label="Submit Review"
        loading={submitting}
      />
    </Box>
  );
};

export default SubmitAppointmentReview;
