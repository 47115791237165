import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import { SmallLoader } from "../../components/loading/Loader";
import CustomButton from "../../components/others/CustomButton";
import TimeSlot from "../../components/others/TimeSlot";
import DisplayServiceRating from "../../components/service/DisplayServiceRating";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { minimizeText } from "../../utils/others/text.utils";

const ServiceBookingPage = () => {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [serviceTimeList, setServiceTimeList] = useState([]);
  const [service, setService] = useState(null);
  const [serviceSchedules, setServiceSchedules] = useState(null);
  const [bookedTimeLoading, setBookedTimeLoading] = useState(false);
  const [serviceBookingLoading, setServiceBookingLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const today = moment().format("YYYY-MM-DD");
  const threeMonthsLater = moment().add(3, "months").format("YYYY-MM-DD");

  useEffect(() => {
    getDoctorSchedule();
    setShowBottomNavigator(false);
    setTopLabel("Appointment Booking");
    if (typeof window !== "undefined" && window?.location?.href?.includes("type=search")) {
      setActiveSidebarText("search");
    } else if (typeof window !== "undefined" && window?.location?.href?.includes("type=map")) {
      setActiveSidebarText("map");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveSidebarText]);

  const getDoctorSchedule = async () => {
    setLoading(true);
    try {
      const userDocRef = doc(db, "users", serviceId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setService(userData);
        setServiceSchedules(userData.schedules || {});
      }
    } catch (error) {
      console.error("Error fetching doctor schedule:", error);
    } finally {
      setLoading(false);
    }
  };

  const getServiceAppointments = async (day) => {
    setBookedTimeLoading(true);
    try {
      const appointmentsRef = query(
        collection(db, "userAppointments"),
        where("bookedDate", "==", day)
      );
      const snapshot = await getDocs(appointmentsRef);

      const dayOfWeek = moment(day).format("dddd");
      const availableTimes = serviceSchedules[dayOfWeek] || [];

      const serviceBookings = [];
      snapshot.forEach((doc) => {
        const app = doc.data();
        if (app.serviceId === serviceId) {
          serviceBookings.push(app);
        }
      });

      const updatedTimeList = availableTimes.map((time) => ({
        apptime: time,
        isBooked: serviceBookings.some(
          (app) => app.bookedTime === time && !app.isCancelled
        ),
      }));

      setServiceTimeList(updatedTimeList);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    } finally {
      setBookedTimeLoading(false);
    }
  };

  const handleDateChange = (date) => {
    const currentData = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(currentData);
    getServiceAppointments(currentData);
  };

  const handleOpenDialog = () => {
    if (selectedDate && selectedTime && userData) {
      setOpenConfirmDialog(true);
    } else {
      if (!userData) {
        toast.error("Please log in to access this resource.");
        navigate("/login");
      } else if (!selectedDate || !selectedTime) {
        toast.error("Please select a date and time.");
      }
    }
  };

  const confirmBooking = () => {
    setOpenConfirmDialog(false);
    pushAppointment();
  };

  const pushAppointment = async () => {
    const userId = userData.uid;
    const appType = service.specialization;
    const bookedDate = selectedDate;
    const bookedTime = selectedTime;

    try {
      setServiceBookingLoading(true);

      const appointmentsRef = query(
        collection(db, "userAppointments"),
        where("bookedDate", "==", bookedDate),
        where("serviceId", "==", serviceId),
        where("bookedTime", "==", bookedTime),
        where("isCancelled", "==", false)
      );

      const snapshot = await getDocs(appointmentsRef);

      if (!snapshot.empty) {
        toast.error("The selected time slot is already booked.");
        return;
      }

      await addDoc(collection(db, "userAppointments"), {
        userId,
        serviceId,
        appType,
        bookedDate,
        bookedTime,
        isCompleted: false,
        isCancelled: false,
        ratings: 0,
        reviews: "",
        messages: [],
        cancellationReason: "",
        cancellationTime: "",
        completedTime: "",
        cancelledBy: "",
      });

      toast.success("Your appointment has been scheduled!");

      navigate("/dashboard");
    } catch (error) {
      toast.error("An error occurred while scheduling your appointment.");
      console.error(error);
    } finally {
      setServiceBookingLoading(false);
    }
  };

  return (
    <SidebarWrapper>
      <Box>
        {loading ? (
          <SmallLoader />
        ) : (
          <>
            <Paper
              sx={{
                display: "flex",
                padding: 2,
                borderRadius: "0.7rem",
                marginBottom: "1rem",
                alignItems: "flex-start",
              }}
              elevation={3}
            >
              <Avatar
                src={service?.avatar}
                alt={service?.firstName}
                sx={{ width: 100, height: 100, marginRight: 2 }}
              />
              <Box>
                <Typography variant="h6">
                  {!service?.firstName?.toLowerCase()?.startsWith("dr.") &&
                    "Dr. "}
                  {service?.firstName} {service?.lastName}
                </Typography>
                <DisplayServiceRating serviceId={serviceId} />
                <Typography variant="body2">
                  🩺 {service.specialization}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: customColors.color_primary }}
                >
                  📍 {minimizeText(service.location || service.hospital, 25)}
                </Typography>
              </Box>
            </Paper>

            <Typography variant="h6">Select Day:</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                onChange={(date) => handleDateChange(date)}
                minDate={dayjs(today)}
                maxDate={dayjs(threeMonthsLater)}
              />
            </LocalizationProvider>

            {selectedDate && (
              <>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Select Time:
                </Typography>
                <Paper
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "1rem",
                    borderRadius: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  {bookedTimeLoading ? (
                    <SmallLoader />
                  ) : serviceTimeList.length > 0 ? (
                    serviceTimeList.map((time, index) => (
                      <TimeSlot
                        key={index}
                        time={time}
                        onClick={() => setSelectedTime(time.apptime)}
                        isSelected={selectedTime === time.apptime}
                        isBooked={time.isBooked}
                      />
                    ))
                  ) : (
                    <Typography color="error">
                      No available time slots for this day.
                    </Typography>
                  )}
                </Paper>
              </>
            )}

            <CustomButton
              onClick={handleOpenDialog}
              disabled={serviceBookingLoading || !selectedDate || !selectedTime}
              loading={serviceBookingLoading}
              label="Confirm Booking"
            />
          </>
        )}
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Creating Appointment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your appointment will be created and your email and phone number
            will be shared with the healthcare professional(s), do you confirm?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Disagree</Button>
          <Button onClick={confirmBooking} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </SidebarWrapper>
  );
};

export default ServiceBookingPage;
