import { Box, Paper, Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";

const BookingHistoryPage = () => {
  const navigate = useNavigate();

  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [bookingHistoryData, setBookingHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Booking History");
    setActiveSidebarText("profile");
  }, [setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  const getUserAppointments = useCallback(
    async (uid) => {
      try {
        setLoading(true);
        const q = query(
          collection(db, "userAppointments"),
          where(
            userData?.role === "patient" ? "userId" : "serviceId",
            "==",
            uid
          )
        );
        const querySnapshot = await getDocs(q);
        const appointments = [];
        const today = new Date();
        today.setHours(24, 0, 0, 0); // Set to upcoming midnight of today

        querySnapshot.forEach((doc) => {
          const currentData = doc.data();
          const bookedDate = new Date(currentData.bookedDate);

          if (bookedDate < today) {
            appointments.push({ id: doc.id, ...currentData });
          }
        });

        setBookingHistoryData(appointments);
        setLoading(false);
      } catch (error) {
        console.error("Error while getting appointments:", error);
        setLoading(false);
      }
    },
    [userData]
  );

  useEffect(() => {
    if (userData) {
      getUserAppointments(userData.uid);
    }
  }, [userData, getUserAppointments]);

  return (
    <SidebarWrapper>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <SmallLoader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            my: "1rem",
          }}
        >
          {bookingHistoryData.map((booking) => (
            <Paper
              key={booking.id}
              elevation={3}
              sx={{
                padding: "1rem",
                marginY: "0.7rem",
                backgroundColor: customColors.color_white,
                cursor: "pointer",
                borderRadius: "1rem",
                width: { md: "30%", xs: "100%", sm: "45%" },
              }}
              onClick={() =>
                navigate(`/appointment/${booking.id}?type=history`)
              }
            >
              <Typography variant="h6" sx={{ color: customColors.color_gray }}>
                {booking.appType}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: customColors.color_gray }}
              >{`${booking.bookedDate} | ${booking.bookedTime}`}</Typography>
              {!(booking.isCompleted || booking.isCancelled) && (
                <Typography color="warning">
                  Consultation is not completed
                </Typography>
              )}
              {booking.isCancelled && (
                <Typography color="error">❌ Cancelled</Typography>
              )}
              {booking.isCompleted && (
                <Typography color="success">✅ Completed</Typography>
              )}
            </Paper>
          ))}
          {bookingHistoryData.length === 0 && (
            <Typography variant="h6" color="error" align="center">
              You don't have any previous reservations!
            </Typography>
          )}
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default BookingHistoryPage;
