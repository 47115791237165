import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import { AuthContext } from "../../contexts/Auth.context";

const DocNurseCard = ({ docNurse }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const goToServiceBookingScreen = () => {
    if (userData.role === "doctor" || userData.role === "nurse") {
      navigate(`/doctors-chat/${docNurse.id}`);
    } else {
      navigate(`/service-booking/${docNurse.id}?type=search`);
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "20px",
        my: "0.7rem",
        width: { md: "30%", xs: "100%", sm: "45%" },
        backgroundColor: customColors.color_white,
        cursor: "pointer",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.02)",
        },
        zIndex: 100,
      }}
      onClick={goToServiceBookingScreen}
    >
      <Box sx={{ padding: "16px" }}>
        <Avatar
          src={docNurse.avatar}
          alt={docNurse?.firstName}
          sx={{
            borderRadius: "50%",
            width: "72px",
            height: "72px",
            border: `2px solid ${customColors.color_primary}`,
            zIndex: 100,
          }}
        />
      </Box>
      <CardContent sx={{ flex: 1, paddingLeft: "16px" }}>
        <Typography variant="h6" component="div">
          {!docNurse?.firstName?.toLowerCase()?.startsWith("dr.") && "Dr. "}
          {docNurse?.firstName} {docNurse?.lastName}
        </Typography>
        <Typography variant="body2">📍 {docNurse?.hospital}</Typography>
        <Typography variant="body2">🩺 {docNurse?.specialization}</Typography>
      </CardContent>
    </Card>
  );
};

export default DocNurseCard;
