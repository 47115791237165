import React, { useContext, useEffect } from "react";
import UserWidget from "../../components/dashboard/UserWidget";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import AdminWidget from "../../components/dashboard/AdminWidget";
import HospitalWidget from "../../components/dashboard/HospitalWidget";
import { toast } from "react-toastify";
import axios from "axios";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";
import { IP_CONFIG_API_KEY } from "../../Constants";

const Dashboard = () => {
  const { userData, fetchUserData } = useContext(AuthContext);
  const {
    setShowBottomNavigator,
    setActiveBottomNavText,
    setActiveSidebarText,
  } = useContext(ActiveSidebarContext);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setActiveBottomNavText("dashboard");
    setActiveSidebarText("dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator]);

  
  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.error("Geolocation is not supported by your browser.");
      getIPLocation();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          updateUserLocation(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log("Error fetching location: " + error.message);
          getIPLocation();
        }
      );
    }
  };

  const getIPLocation = async () => {
    try {
      const response = await fetch(
        `https://ipinfo.io/json?token=${IP_CONFIG_API_KEY}`
      );
      const data = await response.json();
      const [lat, lng] = data.loc.split(",");
      updateUserLocation(lat, lng);
    } catch (err) {
      toast.error("Unable to get location via IP.");
    }
  };

  const updateUserLocation = async (latitude, longitude) => {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    );

    if (response.data) {
      const address = response.data.display_name;

      await updateDoc(doc(db, "users", userData.uid), {
        location: address,
        location_coords: {
          latitude,
          longitude,
        },
      });
    } else {
      console.log("Error fetching address");
    }
  };

  useEffect(() => {
    if (!userData?.location) {
      getLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <SidebarWrapper>
      {(userData?.role === "patient" ||
        userData?.role === "doctor" ||
        userData?.role === "nurse") && <UserWidget />}

      {userData?.role === "admin" && <AdminWidget />}

      {userData?.role === "hospital" && <HospitalWidget />}
    </SidebarWrapper>
  );
};

export default Dashboard;
