import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebaseConfig";

// Convert image URI to Blob
const uriToBlob = async (uri) => {
  try {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error converting URI to Blob:", error);
    throw new Error("Failed to convert URI to Blob");
  }
};

// Function to upload an image to Firebase Storage
const uploadImageToFirebase = async (imageUri) => {
  try {
    const blob = await uriToBlob(imageUri); // Convert image URI to Blob

    const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
    const fileName = `avatars/${Date.now()}_${randomNumber}`;
    const storageRef = ref(storage, fileName); // Create a reference to the 'avatars/' folder

    // Upload the file
    await uploadBytes(storageRef, blob);

    // Get the download URL
    const downloadUrl = await getDownloadURL(storageRef);

    return downloadUrl; // Return the download URL for further use
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload image to Firebase");
  }
};

export default uploadImageToFirebase;
