import { Link } from "react-router-dom";
import allHomeImages from "../../assets/home/allHomeImages";

const Home = () => {
  return (
    <div className="careers">
      <section className="first-container" id="header">
        <header className="container-48">
          <h1 className="wise-doctor">Wise Doctor</h1>
          <div className="about-us-button">
            <div className="second-container">
              <Link
                style={{
                  color: "white",
                  backgroundColor: "#aacfe2",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.7rem",
                  fontSize: "1.15rem",
                  textAlign: "center",
                  fontWeight: 700,
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                }}
                to={"/signin"}
              >
                Log In
              </Link>
            </div>
          </div>
        </header>
        <div className="container-33-parent">
          <div className="container-33">
            <div className="third-container">
              <div className="card-frame">
                <div className="container-34">
                  <img
                    className="image-20-icon"
                    loading="lazy"
                    alt=""
                    src={allHomeImages.img202x}
                  />
                </div>
              </div>
              <button className="container-35">
                <img
                  className="image-21-icon"
                  alt=""
                  src={allHomeImages.img212x}
                />
              </button>
            </div>
            <div className="footer-container">
              <div className="connection-frame">
                <div className="doctors-connecting-frame">
                  <h1 className="medicare-become-easy">Medicare became Easy</h1>
                  <img
                    className="image-29-icon"
                    loading="lazy"
                    alt=""
                    src={allHomeImages.img292x}
                  />
                </div>
                <div className="launch-frame">
                  <button className="button-10">
                    <div className="get-started">Get Started</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="fifth-frame">
              <img
                className="container-36-icon"
                loading="lazy"
                alt=""
                src={allHomeImages.img362x}
              />
            </div>
            <button className="container-37">
              <img
                className="image-23-icon"
                alt=""
                src={allHomeImages.img232x}
              />
            </button>
          </div>
          <div className="launched-soon-frame">
            <div className="container-49">
              <h1 className="who-we-are">Who is Wise Doctor</h1>
              <div className="text-with-chevron-down">
                <div className="privacy-terms-sitemap-frame">
                  <div className="connect-container">
                    <div className="connect-favorite-leaf-camera">
                      <img
                        className="asterisk-1-icon"
                        loading="lazy"
                        alt=""
                        src={allHomeImages.asterisk}
                      />
                    </div>
                    <h3 className="reprehenderit-minim">Record Manager</h3>
                  </div>
                  <div className="english-textbox-frame">
                    <div className="ex-cupidatat-tempor">
                      All the patient's records, history and ongoing treatment
                      at one place
                    </div>
                  </div>
                </div>
                <img
                  className="text-with-chevron-down-child"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.line5}
                />

                <div className="privacy-terms-sitemap-frame1">
                  <div className="frame-parent">
                    <div className="smiley-1-wrapper">
                      <img
                        className="smiley-1-icon"
                        loading="lazy"
                        alt=""
                        src={allHomeImages.smileyimg}
                      />
                    </div>
                    <h3 className="ea-aliqua">Your Scheduler</h3>
                  </div>
                  <div className="ex-cupidatat-tempor-labore-eiu-wrapper">
                    <div className="ex-cupidatat-tempor1">
                      Medicine schedule, checkup schedule all in one app
                    </div>
                  </div>
                </div>
                <img
                  className="text-with-chevron-down-item"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.line5}
                />

                <div className="privacy-terms-sitemap-frame2">
                  <div className="frame-group">
                    <div className="share-network-1-wrapper">
                      <img
                        className="share-network-1"
                        loading="lazy"
                        alt=""
                        src={allHomeImages.shareNetworkimg}
                      />
                    </div>
                    <h3 className="id-veniam-in">Hospital at your home</h3>
                  </div>
                  <div className="consectetur-laboris-cupidatat-wrapper">
                    <div className="consectetur-laboris-cupidatat">
                      From patient to doctor, everybody connected without any
                      hinderance
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="vision-section" id="vision-section">
        <div className="container-39">
          <div className="header-text-frame"></div>
          <h1 className="our-vision">Our Vision</h1>
          <div className="sidebar-frame">
            <div className="container-40">
              <div className="secondary-container-frame">
                <img
                  className="connect-1-icon"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.connect1}
                />
              </div>
              <h2 className="connecting-doctors-and">
                Connecting Doctors and Patients
              </h2>
            </div>
            <div className="container-41">
              <div className="favorite-1-wrapper">
                <img
                  className="favorite-1-icon"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.favoriteImg}
                />
              </div>
              <h2 className="heath">Health</h2>
            </div>
            <div className="container-42">
              <div className="leaf-1-wrapper">
                <img
                  className="leaf-1-icon"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.leafimg}
                />
              </div>
              <h2 className="medical-records">Medical Records</h2>
            </div>
            <div className="container-43">
              <div className="camera-flash-1-wrapper">
                <img
                  className="camera-flash-1"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.cameraflask}
                />
              </div>
              <h2 className="speed-up-diagonosis-container">
                <p className="speed-up">Speed Up</p>
                <p className="diagonosis">Diagnosis</p>
              </h2>
            </div>
            <div className="container-44">
              <div className="home-1-wrapper">
                <img
                  className="home-1-icon"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.homeImg}
                />
              </div>
              <h2 className="consult-from-home">Consult from Home</h2>
            </div>
            <div className="container-45">
              <div className="save-planet-1-wrapper">
                <img
                  className="save-planet-1"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.saveplanetimg}
                />
              </div>
              <h2 className="globally-serving">Globally Serving</h2>
            </div>
            <div className="container-45">
              <div className="save-planet-1-wrapper">
                <img
                  loading="lazy"
                  alt=""
                  src={allHomeImages.download}
                  className="save-planet-1"
                />
              </div>
              <h2 className="globally-serving">Download Our App</h2>
              <a
                href="./wise-doctor.ipa"
                download
                className="download-link-button"
              >
                <button className="button-18">Download Now</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="connect-page" id="connect-page">
        <div className="container-47">
          <div className="help-center">
            <div className="logo-section">
              <a
                className="about-us1"
                style={{ textDecoration: "none" }}
                href="#header"
              >
                About Us
              </a>
              <Link
                className="features"
                style={{ textDecoration: "none" }}
                to="/signin"
              >
                Log In
              </Link>
              <Link
                className="features"
                style={{ textDecoration: "none" }}
                to="/signup"
              >
                Sign up
              </Link>
              <Link
                className="features"
                style={{ textDecoration: "none" }}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </div>
          </div>

          <div className="textbox-english">
            <div className="favorite-button-wrapper">
              <div className="favorite-button">
                <div
                  className="brand-inc"
                  data-i18n="connect_page_footer_rights"
                ></div>
              </div>
            </div>
            <div className="logo-group">
              <div className="frame-logo">
                <img
                  className="logo-twitter-1"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.twitterimg}
                />

                <img
                  className="logo-facebook-1"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.fbimg}
                />

                <img
                  className="logo-linkedin-1"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.linkedinimg}
                />

                <img
                  className="logo-youtube-1"
                  loading="lazy"
                  alt=""
                  src={allHomeImages.youtubeimg}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
