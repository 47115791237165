import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import CustomButton, {
  CustomOutlinedButton,
} from "../../components/others/CustomButton";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import SlotTimings from "../../utils/data/Times";
import { db } from "../../utils/firebase/firebaseConfig";
import { divideSlotsIntoDuration } from "../../utils/others/calendar.utils";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { SmallLoader } from "../../components/loading/Loader";

const SchedulePage = () => {
  const navigate = useNavigate();

  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Slot Scheduling");
    setActiveSidebarText("profile");
  }, [setShowBottomNavigator, setTopLabel, setActiveSidebarText]);

  const WEEK_DAYS = useMemo(
    () => [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    []
  );

  const SLOT_DURATIONS = useMemo(() => [30, 45, 60, 75, 90], []);

  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [loading, setLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [slotDuration, setSlotDuration] = useState(30);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [fromTimeIndex, setFromTimeIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchSchedule = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "users", userData?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const fetchedSchedule = docSnap.data().schedules || {};
          const orderedSchedule = {};
          WEEK_DAYS.forEach((day) => {
            orderedSchedule[day] = fetchedSchedule[day] || [];
          });
          setSchedule(orderedSchedule);
        } else {
          setSchedule({
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching schedule:", error);
      }
    };
    fetchSchedule();
  }, [WEEK_DAYS, userData?.uid]);

  const handleSave = async () => {
    try {
      setScheduleLoading(true);
      await setDoc(
        doc(db, "users", userData?.uid),
        { schedules: schedule },
        { merge: true }
      );
      toast.success("Schedule updated successfully!");
      navigate("/user");
      setScheduleLoading(false);
    } catch (error) {
      setScheduleLoading(false);
      toast.error(error?.message);
      console.error("Error updating schedule:", error);
    }
  };

  const handleAddSlot = (day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: [...prevSchedule[day], ""],
    }));
  };

  const showModal = (day, index) => {
    setSelectedDay(day);
    setSelectedSlotIndex(index);
    setOpenModal(true);
  };
  const handleConfirmToTime = () => {
    if (!fromTime || !toTime) return; // Check if the time slots are properly selected

    // Generate 30-minute time slots based on the selected times
    const timeSlots = divideSlotsIntoDuration(fromTime, toTime, slotDuration);

    // Create a new array of slots for the selected day and insert the new slots
    const newSlots = [...schedule[selectedDay]];

    // Replace the selected slot with the generated 30-minute time slots
    newSlots.splice(selectedSlotIndex, 1, ...timeSlots); // Insert new slots at selected index, replacing the current slot

    // Close the modal and update the state
    setOpenModal(false);
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [selectedDay]: newSlots, // Update the schedule for the selected day
    }));

    // Clear the fromTime and toTime inputs
    setFromTime(null);
    setToTime(null);
    setSlotDuration(30);
  };

  const handleDeleteSlot = (day, index) => {
    const updatedSlots = [...schedule[day]];

    // Remove the slot at the specified index
    updatedSlots.splice(index, 1);

    // Update the state to remove the deleted slot and rearrange the rest
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: updatedSlots,
    }));
  };

  const resetWholeSchedule = () => {
    setSchedule({
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });
    toast.success(`The whole schedule has been reset`);
  };

  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <>
          {Object.keys(schedule).map((day) => (
            <Box key={day} sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {day}
              </Typography>
              {schedule[day].map((slot, index) => (
                <Paper
                  key={index}
                  sx={{
                    padding: 1,
                    mb: 1,
                    cursor: "pointer",
                    display: "flex", // Added for aligning text and icon
                    justifyContent: "space-between", // Space between text and icon
                    alignItems: "center", // Align content vertically
                    backgroundColor: "#f0f0f0",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                  }}
                >
                  <Typography onClick={() => showModal(day, index)}>
                    {slot || "Select Time Slot"}
                  </Typography>
                  <IconButton
                    onClick={() => handleDeleteSlot(day, index)}
                    sx={{ color: "red" }} // Optional: give delete button red color
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                </Paper>
              ))}
              <Button
                variant="outlined"
                onClick={() => handleAddSlot(day)}
                sx={{ mb: 2 }}
              >
                Add Slot
              </Button>
            </Box>
          ))}

          <CustomButton
            onClick={handleSave}
            loading={scheduleLoading}
            disabled={scheduleLoading}
            label="Save Schedule"
          />

          <CustomOutlinedButton
            onClick={resetWholeSchedule}
            label="Reset Schedule"
            color={customColors.color_red}
          />

          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Paper
              sx={{
                width: { md: "50%", xs: "90%" },
                padding: 4,
                outline: "none",
                borderRadius: "1rem",
                margin: "auto",
                marginTop: { md: "15%", xs: "40%" },
              }}
            >
              <Typography variant="h6" sx={{ my: "1rem" }}>
                Select Time Slot
              </Typography>
              <Typography variant="subtitle1">From</Typography>
              <Select
                value={fromTime || ""}
                onChange={(e) => {
                  const selectedTime = e.target.value;
                  setFromTime(selectedTime);
                  setFromTimeIndex(
                    SlotTimings.findIndex((slot) => slot === selectedTime)
                  );
                }}
                fullWidth
                sx={{ mb: 2 }}
              >
                {SlotTimings.map((slot, index) => (
                  <MenuItem key={index} value={slot}>
                    {slot}
                  </MenuItem>
                ))}
              </Select>

              <Typography variant="subtitle1">To</Typography>
              <Select
                value={toTime || ""}
                onChange={(e) => setToTime(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              >
                {SlotTimings.slice(
                  fromTimeIndex + 1,
                  SlotTimings.length - 1
                ).map((slot, index) => (
                  <MenuItem key={index} value={slot}>
                    {slot}
                  </MenuItem>
                ))}
              </Select>

              <Typography variant="subtitle1">Slot Duration</Typography>
              <Select
                value={slotDuration || ""}
                onChange={(e) => setSlotDuration(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              >
                {SLOT_DURATIONS.map((slot, index) => (
                  <MenuItem key={index} value={slot}>
                    {slot} Min
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: "1rem",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  sx={{
                    color: customColors.color_red,
                    borderColor: customColors.color_red,
                    borderRadius: "1rem",
                  }}
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </LoadingButton>
                <LoadingButton
                  sx={{
                    backgroundColor: customColors.color_primary,
                    color: customColors.color_white,
                    borderRadius: "1rem",
                    px: "1rem",
                    ":hover": { backgroundColor: customColors.color_primary },
                  }}
                  onClick={handleConfirmToTime}
                >
                  Complete
                </LoadingButton>
              </Box>
            </Paper>
          </Modal>
        </>
      )}
    </SidebarWrapper>
  );
};

export default SchedulePage;
