import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { orderSchedules } from "../others/service.utils";

export const getAllHospitals = async () => {
  try {
    const doctorsQuery = query(
      collection(db, "users"),
      where("role", "==", "hospital")
    );
    const querySnapshot = await getDocs(doctorsQuery);

    const hospitalsData = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const appointmentsQuery = query(
          collection(db, "userAppointments"),
          where("serviceId", "==", doc.id)
        );
        const aptmtquerySnapshot = await getDocs(appointmentsQuery);
        let appointments = aptmtquerySnapshot.size;
        return { id: doc.id, ...doc.data(), appointments };
      })
    );

    const hospitalRows = hospitalsData.map((d) => ({
      id: d.id,
      firstName: d.firstName,
      lastName: d.lastName,
      specialization: d.specialization,
      qualification: d.qualification,
      location: d.location,
      phoneNumber: d.phoneNumber,
      appointments: d.appointments,
      avatar: d.avatar,
    }));

    return { hospitalsData, hospitalRows };
  } catch (error) {
    console.error("Error fetching Hospitals:", error);
    throw new Error(error.message);
  }
};

export const acceptUserByHospital = async (hospitalId, userId) => {
  try {
    const hospitalRef = doc(db, "hospitals", hospitalId);
    const hospitalSnapshot = await getDoc(hospitalRef);

    if (hospitalSnapshot.exists()) {
      const hospitalData = hospitalSnapshot.data();
      const users = hospitalData.users || [];
      // Find the user to accept
      const userToAccept = users.find((user) => user.userId === userId);
      if (userToAccept) {
        // Update the `accepted` field to true
        const updatedUser = { ...userToAccept, accepted: true };

        // Remove the old user object and add the updated one
        await updateDoc(hospitalRef, {
          users: arrayRemove(userToAccept), // Remove old user object
        });
        await updateDoc(hospitalRef, {
          users: arrayUnion(updatedUser), // Add updated user object
        });

        toast.success(`User ${userId} has been accepted.`);
        return;
      } else {
        throw new Error("User not found in the hospital.");
      }
    } else {
      throw new Error("Hospital not found.");
    }
  } catch (error) {
    console.error("Error accepting user:", error);
    throw new Error("Failed to accept the user.");
  }
};

export const rejectUserByHospital = async (hospitalId, userId) => {
  try {
    const hospitalRef = doc(db, "hospitals", hospitalId);
    const hospitalSnapshot = await getDoc(hospitalRef);

    if (hospitalSnapshot.exists()) {
      const hospitalData = hospitalSnapshot.data();
      const users = hospitalData.users || [];

      // Find the user to reject
      const userToReject = users.find((user) => user.userId === userId);
      if (userToReject) {
        // Remove the user from the array
        await updateDoc(hospitalRef, {
          users: arrayRemove(userToReject), // Remove the user from the array
        });

        toast.success(`User ${userId} has been rejected and removed.`);
      } else {
        toast.error("User not found in the hospital.");
      }
    } else {
      toast.error("Hospital not found.");
    }
  } catch (error) {
    console.error("Error rejecting user:", error);
    toast.error("Failed to reject the user.");
  }
};

// Unified function for accepted users (divided by roles) and their rows
export const getAcceptedUsersAndRows = async (hospitalId) => {
  try {
    const hospitalRef = doc(db, "hospitals", hospitalId); // Reference to the hospital document
    const hospitalSnapshot = await getDoc(hospitalRef);

    if (hospitalSnapshot.exists()) {
      const hospitalData = hospitalSnapshot.data();
      const users = hospitalData.users || [];

      // Filter users who are accepted
      const acceptedUsers = users.filter((user) => user.accepted === true);

      // Divide accepted users by role
      const doctors = acceptedUsers.filter((user) => user.role === "doctor");
      const nurses = acceptedUsers.filter((user) => user.role === "nurse");
      const patients = acceptedUsers.filter((user) => user.role === "patient");

      // Fetch associated doctors, nurses, and patients from Firestore
      const doctorsQuery = query(
        collection(db, "users"),
        where("role", "==", "doctor")
      );
      const nursesQuery = query(
        collection(db, "users"),
        where("role", "==", "nurse")
      );
      const patientsQuery = query(
        collection(db, "users"),
        where("role", "==", "patient")
      );

      const [doctorsSnapshot, nursesSnapshot, patientsSnapshot] =
        await Promise.all([
          getDocs(doctorsQuery),
          getDocs(nursesQuery),
          getDocs(patientsQuery),
        ]);

      // Helper to create user rows
      const createUserRow = (user, appointments, roleSpecificFields = {}) => ({
        id: user.uid,
        firstName: user.firstName,
        lastName: user.lastName,
        location: user.location,
        phoneNumber: user.phoneNumber,
        avatar: user.avatar || "",
        appointments,
        ...roleSpecificFields,
      });

      // Process doctors
      const doctorsData = await Promise.all(
        doctorsSnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("serviceId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return createUserRow({ uid: doc.id, ...doc.data() }, appointments, {
            specialization: doc.data().specialization,
            qualification: doc.data().qualification,
            schedules: orderSchedules(doc.data().schedules),
          });
        })
      );

      // Process nurses
      const nursesData = await Promise.all(
        nursesSnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("serviceId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return createUserRow({ uid: doc.id, ...doc.data() }, appointments, {
            specialization: doc.data().specialization,
            qualification: doc.data().qualification,
            schedules: orderSchedules(doc.data().schedules),
          });
        })
      );

      // Process patients
      const patientsData = await Promise.all(
        patientsSnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("userId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return createUserRow({ uid: doc.id, ...doc.data() }, appointments, {
            meds: doc.data().meds,
            treatment: doc.data().treatment,
          });
        })
      );

      // Create rows for doctors, nurses, and patients
      const acceptedDoctorsRows = doctorsData.filter((doctor) =>
        acceptedUsers.some((user) => user.userId === doctor.id)
      );
      const acceptedNursesRows = nursesData.filter((nurse) =>
        acceptedUsers.some((user) => user.userId === nurse.id)
      );
      const acceptedPatientsRows = patientsData.filter((patient) =>
        acceptedUsers.some((user) => user.userId === patient.id)
      );

      return {
        doctors,
        nurses,
        patients,
        acceptedDoctorsRows,
        acceptedNursesRows,
        acceptedPatientsRows,
      };
    } else {
      throw new Error("Hospital not found.");
    }
  } catch (error) {
    console.error("Error fetching accepted users and rows:", error);
    throw new Error(error.message);
  }
};

// Unified function for pending users and their rows
export const getPendingUsersAndRows = async (hospitalId) => {
  try {
    const hospitalRef = doc(db, "hospitals", hospitalId); // Reference to the hospital document
    const hospitalSnapshot = await getDoc(hospitalRef);

    if (hospitalSnapshot.exists()) {
      const hospitalData = hospitalSnapshot.data();
      const users = hospitalData.users || [];

      // Filter users who are accepted
      const pendingUsers = users.filter((user) => user.accepted === false);

      // Divide accepted users by role
      const doctors = pendingUsers.filter((user) => user.role === "doctor");
      const nurses = pendingUsers.filter((user) => user.role === "nurse");
      const patients = pendingUsers.filter((user) => user.role === "patient");

      // Fetch associated doctors, nurses, and patients from Firestore
      const doctorsQuery = query(
        collection(db, "users"),
        where("role", "==", "doctor")
      );
      const nursesQuery = query(
        collection(db, "users"),
        where("role", "==", "nurse")
      );
      const patientsQuery = query(
        collection(db, "users"),
        where("role", "==", "patient")
      );

      const [doctorsSnapshot, nursesSnapshot, patientsSnapshot] =
        await Promise.all([
          getDocs(doctorsQuery),
          getDocs(nursesQuery),
          getDocs(patientsQuery),
        ]);

      // Helper to create user rows
      const createUserRow = (user, appointments, roleSpecificFields = {}) => ({
        id: user.uid,
        firstName: user.firstName,
        lastName: user.lastName,
        location: user.location,
        phoneNumber: user.phoneNumber,
        avatar: user.avatar || "",
        appointments,
        ...roleSpecificFields,
      });

      // Process doctors
      const doctorsData = await Promise.all(
        doctorsSnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("serviceId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return createUserRow({ uid: doc.id, ...doc.data() }, appointments, {
            specialization: doc.data().specialization,
            qualification: doc.data().qualification,
            schedules: orderSchedules(doc.data().schedules),
          });
        })
      );

      // Process nurses
      const nursesData = await Promise.all(
        nursesSnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("serviceId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return createUserRow({ uid: doc.id, ...doc.data() }, appointments, {
            specialization: doc.data().specialization,
            qualification: doc.data().qualification,
            schedules: orderSchedules(doc.data().schedules),
          });
        })
      );

      // Process patients
      const patientsData = await Promise.all(
        patientsSnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("userId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return createUserRow({ uid: doc.id, ...doc.data() }, appointments, {
            meds: doc.data().meds,
            treatment: doc.data().treatment,
          });
        })
      );

      // Create rows for doctors, nurses, and patients
      const pendingDoctorsRows = doctorsData.filter((doctor) =>
        pendingUsers.some((user) => user.userId === doctor.id)
      );
      const pendingNursesRows = nursesData.filter((nurse) =>
        pendingUsers.some((user) => user.userId === nurse.id)
      );
      const pendingPatientsRows = patientsData.filter((patient) =>
        pendingUsers.some((user) => user.userId === patient.id)
      );

      return {
        doctors,
        nurses,
        patients,
        pendingDoctorsRows,
        pendingNursesRows,
        pendingPatientsRows,
      };
    } else {
      throw new Error("Hospital not found.");
    }
  } catch (error) {
    console.error("Error fetching accepted users and rows:", error);
    throw new Error(error.message);
  }
};
