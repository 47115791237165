export const customColors = {
  color_primary: "#AACFE1",
  color_secondary: "#DDA0DD",
  color_light_gray: "#E0E0E0",
  color_gray: "#61677A",
  color_white: "#fff",
  color_red: "#cc0a17",
  color_red_bg: "#fcb3b5",
  color_green: "#046604",
  color_green_bg: "#c4f2c7",
};
