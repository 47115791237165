import React, { useContext, useEffect } from "react";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import RegisterHospital from "../../components/admin/hospitals/RegisterHospital";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AdminPage } from "../../utils/others/AccessController";

const Hospitals = () => {
  const { setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  useEffect(() => {
    setTopLabel("Hospitals");
    setActiveSidebarText("admin-hospitals");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveSidebarText, setTopLabel]);
  return (
    <AdminPage>
      <SidebarWrapper>
        <RegisterHospital />
      </SidebarWrapper>
    </AdminPage>
  );
};

export default Hospitals;
