import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/auth/backgroundsearch.png";
import { customColors } from "../../assets/styles/Theme";
import SearchBar from "../others/SearchBar";
import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth.context";

const WelcomeCard = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: { md: "center", xs: "cover" },
        backgroundPosition: "center",
        overflow: "hidden",
        padding: "16px",
        margin: "16px",
        minHeight: { xs: "25vh", md: "20vh" },
        maxWidth: "100%",
      }}
    >
      {/* Welcome Section */}
      <Box
        sx={{
          marginTop: "8px",
          marginBottom: "32px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginRight: "8px", color: customColors.color_white }}
        >
          Welcome
          <span style={{ fontWeight: "bold" }}>
            {userData
              ? `, ${
                  userData?.role === "doctor" &&
                  !userData?.firstName?.toLowerCase()?.startsWith("dr.")
                    ? "Dr. "
                    : ""
                }${userData?.firstName}`
              : ""}
          </span>
        </Typography>
      </Box>

      {/* Search Section */}
      <Box>
        <SearchBar
          placeholder="Search by name or specialization..."
          onSearch={() => navigate("/search")}
        />
      </Box>
    </Box>
  );
};

export default WelcomeCard;
