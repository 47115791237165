import { createContext, useState } from "react";

export const ActiveSidebarContext = createContext();

const ActiveSidebarContextProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeSidebarText, setActiveSidebarText] = useState("dashboard");
  const [showBottomNavigator, setShowBottomNavigator] = useState(false);
  const [activeBottomNavText, setActiveBottomNavText] = useState("dashboard");
  const [topLabel, setTopLabel] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  return (
    <ActiveSidebarContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
        activeSidebarText,
        setActiveSidebarText,
        showBottomNavigator,
        setShowBottomNavigator,
        activeBottomNavText,
        setActiveBottomNavText,
        activeCategory,
        setActiveCategory,
        topLabel,
        setTopLabel,
        collapsed,
        setCollapsed,
      }}
    >
      {children}
    </ActiveSidebarContext.Provider>
  );
};

export default ActiveSidebarContextProvider;
