function parseDate(dateString) {
  const [year, month, day] = dateString.split("-");
  return new Date(year, month - 1, day);
}

export function sortAppointmentsByDateAndTime(appointmentList) {
  return appointmentList.slice().sort((a, b) => {
    // Tarih karşılaştırması
    const dateComparison =
      parseDate(a.bookedDate).getTime() - parseDate(b.bookedDate).getTime();
    if (dateComparison === 0) {
      // Eğer tarihler aynıysa saatlere göre sırala
      return a.bookedTime.localeCompare(b.bookedTime);
    }
    return dateComparison;
  });
}

export const formatDateToLong = (dateString) => {
  const date = new Date(dateString); // Create a new Date object

  // Options for formatting the date
  const options = { year: "numeric", month: "long", day: "numeric" };

  // Return the formatted date string
  return date.toLocaleDateString("en-US", options);
};

export const formatChatTime = (time) => {
  const date = new Date(time);
  const today = new Date();

  const isToday = date.toDateString() === today.toDateString();

  if (isToday) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  } else {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }
};

export const divideSlotsIntoDuration = (startTime, endTime, duration) => {
  // Helper function to convert time string (e.g. "8:00 AM") into a Date object
  const convertToTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0; // Midnight case
    }

    return new Date(0, 0, 0, hours, minutes); // Date object with the parsed time
  };

  // Helper function to format the time from Date object back to string (e.g. "8:00 AM")
  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const isPM = hours >= 12;

    hours = hours % 12 || 12; // Convert to 12-hour format
    const modifier = isPM ? "PM" : "AM";

    return `${hours}:${minutes.toString().padStart(2, "0")} ${modifier}`;
  };

  const start = convertToTime(startTime);
  const end = convertToTime(endTime);

  const slots = [];
  let current = new Date(start);

  while (current < end) {
    const next = new Date(current);
    next.setMinutes(current.getMinutes() + duration);

    // Add the current slot to the list
    slots.push(`${formatTime(current)} - ${formatTime(next)}`);

    // Move to the next slot
    current = next;
  }

  return slots;
};
