import React from "react";

const PrivacyPolicy = () => {
  const pageStyle = {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    backgroundColor: "#f0f8ff",
    color: "#333",
    lineHeight: "1.6",
    maxWidth: "1200px",
    margin: "auto",
  };

  const headerStyle = {
    textAlign: "center",
    color: "#1e90ff",
    marginBottom: "20px",
  };

  const sectionStyle = {
    marginBottom: "20px",
  };

  const boldText = {
    fontWeight: "bold",
  };

  return (
    <div style={pageStyle}>
      <h1 style={headerStyle}>Privacy Policy for Wise Doctor</h1>

      <div style={sectionStyle}>
        <p>
          <span style={boldText}>Wise Doctor</span> is an application and
          website designed for doctors and patients, aiming to simplify the
          medical process by organizing and preserving important health data,
          reducing medical errors, and providing faster communication.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2>What We Collect</h2>
        <p>We collect the following information from users of our app:</p>
        <ul>
          <li>
            Personal data such as name, contact information, and medical history
          </li>
          <li>Medical records, lab results, and vital signs</li>
          <li>Appointment schedules and treatment plans</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2>Why We Collect Data</h2>
        <p>
          Our app is designed to facilitate communication between doctors,
          patients, and medical staff. By storing medical records, we aim to
          help in:
        </p>
        <ul>
          <li>Providing accurate medical information for emergencies</li>
          <li>Organizing appointments and reducing wait times</li>
          <li>Helping doctors with better diagnosis and treatment</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2>Data Security</h2>
        <p>
          We prioritize the security of your data. All personal and medical
          information is securely stored, encrypted, and backed up to ensure
          that it cannot be accessed or stolen.
        </p>
        <p>
          Your data is safe, and in case of any emergencies, backups are
          available to restore it.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2>Who Uses Wise Doctor?</h2>
        <p>
          <span style={boldText}>Wise Doctor</span> is used by a variety of
          individuals and entities:
        </p>
        <ul>
          <li>Patients and their families</li>
          <li>Doctors across various specializations</li>
          <li>Administrative and medical assistants</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={{ color: "#ff261e" }}>
          Account Deletion Policy for WiseDoctor App{" "}
        </h2>
      </div>

      <div style={sectionStyle}>
        <h2>Account Deletion by User </h2>
        <ul>
          <li>
            Users have the option to delete their account at any time by
            accessing the "Profile" section of the app.
          </li>
          <li>
            In the "Profile" section, users will find an option to delete their
            account.
          </li>
          <li>
            By selecting this option, the user will be prompted to confirm their
            decision to delete their account.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2>Consequences of Account Deletion</h2>
        <ul>
          <li>
            When a user deletes their account, all data associated with that
            account will be permanently deleted from our system.
          </li>
          <li>
            This includes personal information, order history, payment
            preferences, and any other data collected while using the app.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2>Account Deletion Process</h2>
        <p>
          Once the user confirms the deletion of their account, it will be
          irreversible and immediate. No recovery of deleted data will be
          possible after confirmation of account deletion.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2>Communication with the User</h2>
        <p>
          After the account is deleted, the user will receive a confirmation via
          email or in-app notification, indicating that their account has been
          successfully deleted.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2>Contact Customer Service</h2>
        <p>
          If a user encounters any difficulties or needs assistance during the
          account deletion process, our customer service is available to provide
          assistance.{" "}
          <a style={{ color: "#1e90ff" }} href="mailto:info@itscholding.com">
            info@itscholding.com
          </a>
          .
        </p>
      </div>

      <div style={sectionStyle}>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy, feel
          free to contact via{" "}
          <a style={{ color: "#1e90ff" }} href="mailto:info@itscholding.com">
            info@itscholding.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
