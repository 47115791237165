import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Modal, Paper, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import {
  acceptUserByHospital,
  getPendingUsersAndRows,
} from "../../utils/functions/hospitals";
import { HospitalPage } from "../../utils/others/AccessController";
import { toast } from "react-toastify";

const HospitalPendingUsers = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [doctorRows, setDoctorRows] = useState([]);
  const [nurseRows, setNurseRows] = useState([]);
  const [patientRows, setPatientRows] = useState([]);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentUid, setCurrentUid] = useState("");

  useEffect(() => {
    setActiveSidebarText("hospital-all-pending");
    setTopLabel("Pending Users");
    getPendingUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator, setActiveSidebarText, setTopLabel]);

  const getPendingUsers = async () => {
    try {
      setLoading(true);
      const { pendingDoctorsRows, pendingNursesRows, pendingPatientsRows } =
        await getPendingUsersAndRows(userData.uid);
      setDoctorRows(pendingDoctorsRows);
      setNurseRows(pendingNursesRows);
      setPatientRows(pendingPatientsRows);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const doctorColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "specialization", headerName: "Specialization", flex: 0.5 },
    { field: "qualification", headerName: "Qualification", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    { field: "schedules", headerName: "Schedules", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id, "doctor")}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const nurseColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "specialization", headerName: "Specialization", flex: 0.5 },
    { field: "qualification", headerName: "Qualification", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    { field: "schedules", headerName: "Schedules", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id, "nurse")}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const patientColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "treatment", headerName: "Treatment", flex: 0.5 },
    { field: "meds", headerName: "Medications", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    { field: "schedules", headerName: "Schedules", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id, "patient")}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const showModal = (id, role) => {
    let newArr = [];
    setCurrentUid(id);
    if (role === "patient") {
      const currentData = patientRows.find((item) => item.id === id);
      newArr = [
        {
          key1: "ID",
          value1: id,
        },
        {
          key1: "First Name",
          value1: currentData.firstName,
        },
        {
          key1: "Last Name",
          value1: currentData.lastName,
        },
        {
          key1: "Medications",
          value1: currentData.meds,
        },
        {
          key1: "Treatment",
          value1: currentData.treatment,
        },
        {
          key1: "Location",
          value1: currentData.location || "Not Available",
        },
        {
          key1: "Phone Number",
          value1: currentData.phoneNumber,
        },
        {
          key1: "Appointments",
          value1: currentData.appointments,
        },
      ];
    } else if (role === "doctor") {
      const currentData = doctorRows.find((item) => item.id === id);
      newArr = [
        {
          key1: "ID",
          value1: id,
        },
        {
          key1: "First Name",
          value1: currentData.firstName,
        },
        {
          key1: "Last Name",
          value1: currentData.lastName,
        },
        {
          key1: "Specialization",
          value1: currentData.specialization,
        },
        {
          key1: "Qualification",
          value1: currentData.qualification,
        },
        {
          key1: "Location",
          value1: currentData.location || "Not Available",
        },
        {
          key1: "Phone Number",
          value1: currentData.phoneNumber,
        },
        {
          key1: "Appointments",
          value1: currentData.appointments,
        },
      ];
    } else if (role === "nurse") {
      const currentData = nurseRows.find((item) => item.id === id);
      newArr = [
        {
          key1: "ID",
          value1: id,
        },
        {
          key1: "First Name",
          value1: currentData.firstName,
        },
        {
          key1: "Last Name",
          value1: currentData.lastName,
        },
        {
          key1: "Specialization",
          value1: currentData.specialization,
        },
        {
          key1: "Qualification",
          value1: currentData.qualification,
        },
        {
          key1: "Location",
          value1: currentData.location || "Not Available",
        },
        {
          key1: "Phone Number",
          value1: currentData.phoneNumber,
        },
        {
          key1: "Appointments",
          value1: currentData.appointments,
        },
      ];
    }
    setModalData(newArr);
    setOpenModal(true);
  };

  const acceptUserhandler = async () => {
    try {
      setAcceptLoading(true);
      await acceptUserByHospital(userData?.uid, currentUid);
      setAcceptLoading(false);
      setCurrentUid("");
      getPendingUsers();
    } catch (error) {
      toast.error(error.message);
      setAcceptLoading(false);
    }
  };

  const rejectUserHandler = async () => {
    try {
      setRejectLoading(true);
      await rejectUserHandler(userData?.uid, currentUid);
      setRejectLoading(false);
      setCurrentUid("");
      getPendingUsers();
    } catch (error) {
      toast.error(error.message);
      setRejectLoading(false);
    }
  };

  return (
    <HospitalPage>
      <SidebarWrapper>
        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary, my: "0.5rem" }}
          >
            Pending Doctors
          </Typography>
          {doctorRows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Pending Doctors to Approve!
            </Typography>
          ) : (
            <DataGrid
              rows={doctorRows}
              columns={doctorColumns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
            my: "1rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary, my: "0.5rem" }}
          >
            Pending Nurses
          </Typography>
          {nurseRows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Pending Nurses to Approve!
            </Typography>
          ) : (
            <DataGrid
              rows={nurseRows}
              columns={nurseColumns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
            my: "1rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary, my: "0.5rem" }}
          >
            Pending Patients
          </Typography>
          {patientRows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Pending Patients to Approve!
            </Typography>
          ) : (
            <DataGrid
              rows={patientRows}
              columns={patientColumns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        {openModal && (
          <Modal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
              setCurrentUid("");
            }}
          >
            <Paper
              sx={{
                width: "50%",
                padding: 4,
                outline: "none",
                margin: "auto",
                marginTop: { md: "15%", xs: "2vh" },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {modalData.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Typography>{item.key1}: </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.value1}
                    </Typography>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    sx={{
                      color: customColors.color_white,
                      backgroundColor: customColors.color_green,
                      "&:hover": { backgroundColor: customColors.color_green },
                      width: "20%",
                      marginTop: "2rem",
                    }}
                    onClick={acceptUserhandler}
                    loading={acceptLoading}
                    disabled={acceptLoading}
                  >
                    Accept
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    sx={{
                      color: customColors.color_white,
                      backgroundColor: customColors.color_red,
                      "&:hover": { backgroundColor: customColors.color_red },
                      width: "20%",
                      marginTop: "2rem",
                    }}
                    onClick={rejectUserHandler}
                    loading={rejectLoading}
                    disabled={rejectLoading}
                  >
                    Reject
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    sx={{
                      color: customColors.color_primary,
                      borderColor: customColors.color_primary,
                      width: "20%",
                      marginTop: "2rem",
                    }}
                    onClick={() => {
                      setOpenModal(false);
                      setCurrentUid("");
                    }}
                  >
                    Close
                  </LoadingButton>
                </Box>
              </Box>
            </Paper>
          </Modal>
        )}
      </SidebarWrapper>
    </HospitalPage>
  );
};

export default HospitalPendingUsers;
