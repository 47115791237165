import React, { createContext, useState } from "react";

export const SignupContext = createContext();

const SignupContextProvider = ({ children }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("patient");
  return (
    <SignupContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        password,
        setPassword,
        role,
        setRole,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};

export default SignupContextProvider;
