import {
  AccountCircleOutlined,
  CalendarTodayOutlined,
  HomeOutlined,
  PlaceOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { IconButton, Paper } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";

const BottomNavigator = () => {
  const navigate = useNavigate();
  const { activeBottomNavText, setActiveBottomNavText } =
    useContext(ActiveSidebarContext);
  const handleNavigationChange = (newValue) => {
    setActiveBottomNavText(newValue);
    navigate(`/${newValue}`);
  };

  return (
    <Paper
      elevation={9}
      sx={{
        position: "fixed",
        bottom: 23,
        left: 0,
        right: 0,
        boxShadow: `0 -1px 10px ${customColors.color_light_gray}`,
        borderRadius: "15px",
        backgroundColor: "#ffffff",
        padding: "10px 8px",
        display: { xs: "flex", md: "none" },
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        margin: "0 auto",
        height: "3rem",
        zIndex: 99999,
      }}
    >
      {/* Home Button */}
      <IconButton onClick={() => handleNavigationChange("dashboard")}>
        <HomeOutlined
          sx={{
            fontSize: "2rem",
            color:
              activeBottomNavText === "dashboard"
                ? customColors.color_primary
                : customColors.color_gray,
          }}
        />
      </IconButton>

      {/* Search Button */}
      <IconButton onClick={() => handleNavigationChange("search")}>
        <SearchOutlined
          sx={{
            fontSize: "2rem",
            color:
              activeBottomNavText === "search"
                ? customColors.color_primary
                : customColors.color_gray,
          }}
        />
      </IconButton>

      {/* Middle Circular Button (Location) */}
      <IconButton
        onClick={() => handleNavigationChange("map")}
        sx={{
          backgroundColor:
            activeBottomNavText === "map"
              ? customColors.color_green
              : customColors.color_primary,
          color: "#ffffff",
          borderRadius: "50%",
          padding: "10px",
          transform: "translateY(-40%)",
          boxShadow: `0px 4px 10px ${customColors.color_light_gray}`,
          ":hover": {
            backgroundColor:
              activeBottomNavText === "map"
                ? customColors.color_green
                : customColors.color_primary,
          },
        }}
      >
        <PlaceOutlined
          sx={{ fontSize: "2rem", color: customColors.color_white }}
        />
      </IconButton>

      {/* Appointments Button */}
      <IconButton onClick={() => handleNavigationChange("appointments")}>
        <CalendarTodayOutlined
          sx={{
            fontSize: "2rem",
            color:
              activeBottomNavText === "appointments"
                ? customColors.color_primary
                : customColors.color_gray,
          }}
        />
      </IconButton>

      {/* Profile Button */}
      <IconButton onClick={() => handleNavigationChange("user")}>
        <AccountCircleOutlined
          sx={{
            fontSize: "2rem",
            color:
              activeBottomNavText === "user"
                ? customColors.color_primary
                : customColors.color_gray,
          }}
        />
      </IconButton>
    </Paper>
  );
};

export default BottomNavigator;
