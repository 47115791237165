import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { formatChatTime } from "../../utils/others/calendar.utils";
import { customColors } from "../../assets/styles/Theme";

const MessageDisplay = ({ messages, currentRole }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.3rem",
          marginY: 2,
          color: customColors.color_gray,
          fontWeight: 600,
        }}
      >
        Appointment Messages
      </Typography>
      {messages.map((item, index) => {
        // Determine if the message should be on the right or left
        const isCurrentUserMessage =
          (item?.isPatient && currentRole === "patient") ||
          (!item?.isPatient && currentRole === "doctor");

        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: isCurrentUserMessage ? "flex-end" : "flex-start",
            }}
          >
            <Paper
              sx={{
                backgroundColor: isCurrentUserMessage
                  ? customColors.color_primary
                  : customColors.color_white,
                color: isCurrentUserMessage
                  ? customColors.color_white
                  : customColors.color_gray,
                padding: 1,
                width: "auto",
                maxWidth: "60%",
                borderRadius: "1rem",
                marginY: "0.5rem",
              }}
              elevation={3}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: isCurrentUserMessage
                    ? customColors.color_white
                    : customColors.color_gray,
                  fontWeight: 500,
                }}
              >
                {item.message}
              </Typography>
              <Typography
                sx={{
                  fontSize: 9,
                  textAlign: "right",
                  color: customColors.color_gray,
                }}
              >
                {formatChatTime(item?.timestamp)}
              </Typography>
            </Paper>
          </Box>
        );
      })}
    </Box>
  );
};

export default MessageDisplay;
