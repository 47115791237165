import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/Auth.context";
import Dashboard from "../../pages/main/Dashboard";

export const AdminPage = ({ children }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    if (userData?.role !== "admin") {
      navigate("/dashboard");
      toast.error(userData?.role?.toUpperCase() + " can't access this page");
    }
  }, [userData, navigate]);
  return userData.role === "admin" ? children : <Dashboard />;
};

export const HospitalPage = ({ children }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  useEffect(() => {
    if (userData?.role !== "hospital" && userData?.role !== "admin") {
      navigate("/dashboard");
      toast.error(userData?.role?.toUpperCase() + " can't access this page");
    }
  }, [userData, navigate]);
  return userData?.role === "hospital" || userData?.role === "admin" ? (
    children
  ) : (
    <Dashboard />
  );
};
