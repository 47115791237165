import { Box, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { getUserHospitals } from "../../utils/functions/general";
import JoinedHospitalCard from "../../components/cards/JoinedHospitalCard";
import { customColors } from "../../assets/styles/Theme";

const MyHospitals = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(false);

  const gethospitals = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getUserHospitals(userData?.uid);
      setHospitals(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [userData?.uid]);

  useEffect(() => {
    gethospitals();
    setShowBottomNavigator(false);
    setTopLabel("Joined Hospitals");
    setActiveSidebarText("profile");
  }, [gethospitals, setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            my: "1rem",
          }}
        >
          {hospitals?.length > 0 ? (
            <>
              {hospitals.map((hospital, index) => (
                <JoinedHospitalCard key={index} data={hospital} />
              ))}
            </>
          ) : (
            <Typography variant="h6" sx={{ color: customColors.color_red }}>
              No Hospitals Found!
            </Typography>
          )}
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default MyHospitals;
