import { cloneElement } from "react";
import { customColors } from "../../assets/styles/Theme";
import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

const RadioComponent = ({ isActive, onSelect, label, icon }) => {
  const coloredIcon = cloneElement(icon, {
    sx: {
      color: isActive ? customColors.color_primary : customColors.color_gray,
      fontSize: "1.2rem",
    },
  });
  return (
    <Box
      sx={{
        gap: 1,
        border: `1px solid ${
          !isActive ? customColors.color_light_gray : customColors.color_primary
        }`,
        borderRadius: "0.3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.3rem 0.5rem",
        color: `${
          !isActive ? customColors.color_gray : customColors.color_primary
        } !important`,
        cursor: "pointer",
        backgroundColor: !isActive ? customColors.color_white : blue[50],
      }}
      onClick={onSelect}
    >
      {coloredIcon}
      <Typography variant="subtitle2" textTransform="capitalize">
        {label}
      </Typography>
    </Box>
  );
};

export default RadioComponent;
