import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";

const AppointmentCard = ({ appointment }) => {
  const navigate = useNavigate();

  const { appType, bookedDate, bookedTime } = appointment;

  const formattedDate = new Date(bookedDate);
  const day = formattedDate.getDate().toString().padStart(2, "0");
  const month = formattedDate.toLocaleString("default", { month: "short" });

  const handleClick = () => {
    navigate(`/appointment/${appointment.id}`);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: "flex",
        borderRadius: "1rem",
        margin: "0.7rem 1rem",
        padding: "0.5rem",
        width: { md: "30%", xs: "100%", sm: "45%" },
        backgroundColor: appointment?.isCancelled
          ? customColors.color_red_bg
          : appointment?.isCompleted
          ? customColors.color_green_bg
          : customColors.color_white,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          backgroundColor: customColors.color_primary,
          borderRadius: "1rem",
          padding: "16px",
          textAlign: "center",
          color: "#fff",
          marginRight: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ margin: 0 }}>
          {day}
        </Typography>
        <Typography variant="h6" sx={{ margin: 0, fontWeight: "bold" }}>
          {month}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {appType}
          {appointment?.isCancelled && " ❌ Cancelled"}
          {appointment?.isCompleted && " ✅ Completed"}
        </Typography>
        <Typography variant="body2" sx={{ color: customColors.color_gray }}>
          {bookedTime}
        </Typography>
      </Box>
    </Box>
  );
};

export default AppointmentCard;
