import { Box, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { customColors } from "../../../assets/styles/Theme";
import CustomButton from "../../others/CustomButton";

const RegisterHospital = () => {
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateAccount = async () => {
    if (!hospitalName || !hospitalEmail || !password) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);

    try {
      const { data, status } = await axios.post(
        "https://wisedoc-api.onrender.com/api/v1/hospitals/create",
        {
          hospitalName,
          hospitalEmail,
          password,
        }
      );

      if (status === 201 && data) {
        toast.success(data);
        setHospitalName("");
        setHospitalEmail("");
        setPassword("");
      }
    } catch (error) {
      console.error("Error creating hospital account: ", error);
      toast.error("An error occurred while creating the account. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 4,
        width: "100%",
      }}
    >
      <Typography variant="h4" sx={{ color: customColors.color_primary }}>
        Create Hospital Account
      </Typography>

      <TextField
        label="Hospital Name"
        variant="outlined"
        fullWidth
        required
        value={hospitalName}
        onChange={(e) => setHospitalName(e.target.value)}
      />

      <TextField
        label="Hospital Email"
        variant="outlined"
        fullWidth
        required
        type="email"
        value={hospitalEmail}
        onChange={(e) => setHospitalEmail(e.target.value)}
      />

      <TextField
        label="Password"
        variant="outlined"
        fullWidth
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <CustomButton
        onClick={handleCreateAccount}
        loading={loading}
        disabled={loading}
        label="Create Hospital Account"
      />
    </Box>
  );
};

export default RegisterHospital;
