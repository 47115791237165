import {
  Box,
  CircularProgress,
  LinearProgress,
  Paper,
  Toolbar,
} from "@mui/material";
import React from "react";
import allHomeImages from "../../assets/home/allHomeImages";
import { customColors } from "../../assets/styles/Theme";

const Loader = () => {
  return (
    <>
      <Paper
        sx={{
          opacity: 1,
          pointerEvents: "none",
          transition: "all .3s ease",
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 999,
          backgroundColor: customColors.color_primary,
        }}
      >
        <Toolbar />
        <LinearProgress />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={allHomeImages.img292x} alt="" />
        </Box>
      </Paper>
    </>
  );
};

export default Loader;

export const SmallLoader = () => {
  return (
    <div className="w-[100%] flex items-center justify-center">
      <CircularProgress size={50} sx={{ color: customColors.color_primary }} />
    </div>
  );
};
