import { Box } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import CategoryCard from "../../components/cards/CategoryCard";
import DocNurseCard from "../../components/cards/DocNurseCard";
import { SmallLoader } from "../../components/loading/Loader";
import SearchBar from "../../components/others/SearchBar";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import categories from "../../utils/data/Categories";
import { db } from "../../utils/firebase/firebaseConfig";

const SearchPage = () => {
  const { fetchUserData, userData } = useContext(AuthContext);
  const {
    setShowBottomNavigator,
    setActiveBottomNavText,
    activeCategory,
    setActiveCategory,
    setActiveSidebarText,
  } = useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [filteredServiceList, setFilteredServiceList] = useState([]);
  const [docNurse, setDocNurse] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setShowBottomNavigator(true);
    setActiveBottomNavText("search");
    setActiveSidebarText("search");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator, setActiveSidebarText]);

  const handleCategoryCardSelection = (category) => {
    setActiveCategory(category);
    if (activeCategory === category) {
      setFilteredServiceList(serviceList);
    } else {
      const filteredList = filterServicesByCategory(category, serviceList);
      setFilteredServiceList(filteredList);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchDocNurse = async (category) => {
      try {
        setActiveCategory(category);
        setLoading(true);
        const doctorsQuery = query(
          collection(db, "users"), // Assuming 'users' is your collection name
          where("role", "==", activeCategory) // Filter documents where the 'role' field is 'doctor' or 'nurse'
        );

        const querySnapshot = await getDocs(doctorsQuery); // Fetch the query results
        const docNurseRes = [];

        querySnapshot.forEach((doc) => {
          if (doc.id !== userData?.uid) {
            docNurseRes.push({ id: doc.id, ...doc.data() });
          }
        });

        setLoading(false);
        setDocNurse(docNurseRes);
        setData(docNurseRes);
        setServiceList(docNurseRes);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching doctors:", error);
      }
    };

    if (!activeCategory || activeCategory === "") {
      setActiveCategory("dotor");
      fetchDocNurse("doctor");
    } else {
      fetchDocNurse(activeCategory);
    }
  }, [activeCategory, setActiveCategory, userData]);

  const filterServicesByCategory = (category, serviceList) => {
    if (category === "") {
      return serviceList;
    } else {
      const filteredList = serviceList.filter(
        (service) =>
          service?.specialization?.toLowerCase() === category?.toLowerCase()
      );
      return filteredList;
    }
  };

  // Search function
  const handleSearch = (text) => {
    if (text.trim() === "") {
      setData(docNurse);
      return;
    }
    const searchedText = text?.toLowerCase();

    // Create an empty array to hold all matched services
    const allMatchedServices = [];

    for (const service of docNurse) {
      if (
        service.firstName.toLowerCase().includes(searchedText) ||
        service.specialization.toLowerCase().includes(searchedText) ||
        service.lastName.toLowerCase().includes(searchedText) ||
        service.hospital.toLowerCase().includes(searchedText)
      ) {
        allMatchedServices.push(service);
      }
    }

    // Set the filtered service list with all matched services
    setFilteredServiceList(allMatchedServices);
  };

  useEffect(() => {
    if (filteredServiceList.length > 0) {
      setData(filteredServiceList);
    } else {
      setData(docNurse);
    }
  }, [docNurse, filteredServiceList]);

  return (
    <SidebarWrapper>
      <SearchBar
        onSearch={handleSearch}
        placeholder="Search by name or specialization..."
      />

      <Box sx={{ display: "flex", flexWrap: "wrap", my: "1rem" }}>
        {categories.map((category, index) => (
          <CategoryCard
            key={index}
            category={category}
            isSelected={activeCategory === category?.name}
            onPress={() => handleCategoryCardSelection(category?.name)}
          />
        ))}
      </Box>
      {loading ? (
        <SmallLoader />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {data.map((item, index) => (
            <DocNurseCard key={index} docNurse={item} />
          ))}
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default SearchPage;
