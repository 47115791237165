import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { customColors } from "../../assets/styles/Theme"; // Adjust the import path to your custom colors
import { useNavigate } from "react-router-dom";

const AppointmentCardSmall = ({ appointment }) => {
  const navigate = useNavigate();
  const { appType, bookedDate, bookedTime } = appointment;

  const formattedDate = new Date(bookedDate);
  const day = formattedDate.getDate();
  const month = formattedDate.toLocaleString("default", { month: "short" });
  const year = formattedDate.getFullYear();

  const handleClick = () => {
    navigate(`/appointment/${appointment.id}`);
  };

  return (
    <Paper
      onClick={handleClick}
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 2,
        paddingX: 3,
        paddingY: 1,
        marginY: 1,
        width: { md: "30%", xs: "100%", sm: "45%" },
        backgroundColor: customColors.color_white,
        boxShadow: `0px 4px 6px ${customColors.color_gray}20`,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Typography variant="body2" sx={{ fontSize: 14, padding: 0.5 }}>
          {appType}{" "}
          {appointment?.isCancelled && (
            <Typography
              variant="body2"
              component="span"
              sx={{ color: customColors.color_red, marginLeft: 1 }}
            >
              ❌ Cancelled
            </Typography>
          )}
          {appointment?.isCompleted && (
            <Typography
              variant="body2"
              component="span"
              sx={{ color: customColors.color_green, marginLeft: 1 }}
            >
              ✅ Completed
            </Typography>
          )}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: 14, padding: 0.5, color: customColors.color_gray }}
        >
          {bookedTime}, {day} {month} {year} 
        </Typography>
      </Box>
    </Paper>
  );
};

export default AppointmentCardSmall;
