import downloadImg from "./download.png";
import asterisk from "./asterisk-1.svg";
import line4 from "./line-4.svg";
import line5 from "./line-5.svg";
import cameraflask from "./camera-flash-1.svg";
import connect1 from "./connect-1.svg";
import favoriteImg from "./favorite-1.svg";
import homeImg from "./home-1.svg";
import img142x from "./image-14@2x.png";
import img202x from "./image-20@2x.png";
import img212x from "./image-21@2x.png";
import img232x from "./image-23@2x.png";
import img362x from "./container-36@2x.png";
import img292x from "./image-29@2x.png";
import leafimg from "./leaf-1.svg";
import locationPinImg from "./location-pin-1.svg";
import fbimg from "./logo-facebook-1.svg";
import linkedinimg from "./logo-linkedin-1.svg";
import twitterimg from "./logo-twitter-1.svg";
import youtubeimg from "./logo-youtube-1.svg";
import mailimg from "./mail-1.svg";
import phoneimg from "./phone-1.svg";
import rocketLaunchimg from "./rocket-launch-1.svg";
import saveplanetimg from "./save-planet-1.svg";
import shareNetworkimg from "./share-network-1.svg";
import smileyimg from "./smiley-1.svg";

const allHomeImages = {
  asterisk,
  cameraflask,
  connect1,
  download: downloadImg,
  favoriteImg,
  homeImg,
  img142x,
  logo: img292x,
  img202x,
  img212x,
  img232x,
  img292x,
  img362x,
  leafimg,
  line4,
  line5,
  locationPinImg,
  fbimg,
  linkedinimg,
  twitterimg,
  youtubeimg,
  mailimg,
  phoneimg,
  rocketLaunchimg,
  saveplanetimg,
  shareNetworkimg,
  smileyimg,
};

export default allHomeImages;
