import React, { useContext, useEffect } from "react";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AdminPage } from "../../utils/others/AccessController";
import RegisterAdmin from "../../components/admin/admins/RegisterAdmin";

const Admins = () => {
  const { setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  useEffect(() => {
    setTopLabel("Admins");
    setActiveSidebarText("add-admins");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveSidebarText, setTopLabel]);
  return (
    <AdminPage>
      <SidebarWrapper>
        <RegisterAdmin />
      </SidebarWrapper>
    </AdminPage>
  );
};

export default Admins;
