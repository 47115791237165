import { Box, List, Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import UserChatCard from "../../components/cards/UserChatCard";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";

const ChatRoomPage = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [conversationUsers, setConversationUsers] = useState([]);

  const fetchMessages = async () => {
    try {
      setLoading(true);

      // Query to fetch messages where currentUserId is either the sender or receiver
      const messageReceiveQuery = query(
        collection(db, "messages"),
        where("receiver", "==", userData?.uid)
      );
      const messageSendQuery = query(
        collection(db, "messages"),
        where("sender", "==", userData?.uid)
      );

      const snapshotReceive = await getDocs(messageReceiveQuery);
      const snapshotSend = await getDocs(messageSendQuery);

      const combinedMessages = [
        ...snapshotReceive.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...snapshotSend.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ].sort((a, b) => b?.timestamp - a?.timestamp);

      const uniqueUsers = new Set();

      combinedMessages.forEach((message) => {
        if (message.sender !== userData?.uid) {
          uniqueUsers.add(message.sender);
        }
        if (message.receiver !== userData?.uid) {
          uniqueUsers.add(message.receiver);
        }
      });

      setConversationUsers([...uniqueUsers]); // Convert Set to Array
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
    setShowBottomNavigator(true);
    setActiveSidebarText("chat-room");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveSidebarText, setShowBottomNavigator]);

  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <Box>
          <Typography
            variant="h5"
            sx={{ color: customColors.color_primary }}
            gutterBottom
          >
            Chat Room
          </Typography>
          {conversationUsers.length ? (
            <List>
              {conversationUsers.map((userId, index) => (
                <UserChatCard key={index} senderId={userId} />
              ))}
            </List>
          ) : (
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: customColors.color_red,
                marginTop: 4,
              }}
            >
              No new messages.
            </Typography>
          )}
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default ChatRoomPage;
