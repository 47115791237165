import { Box, CircularProgress, Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AppointmentCard from "../../components/cards/AppointmentCard";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { sortAppointmentsByDateAndTime } from "../../utils/others/calendar.utils";
import { customColors } from "../../assets/styles/Theme";

const AppointmentsPage = () => {
  const { userData, fetchUserData } = useContext(AuthContext);
  const { setShowBottomNavigator, setActiveSidebarText } =
    useContext(ActiveSidebarContext);
  const [loading, setLoading] = useState(true);
  const [appointmentList, setAppointmentList] = useState([]);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    fetchUserData(uid);
    setShowBottomNavigator(true);
    setActiveSidebarText("appointments");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator, setActiveSidebarText]);

  const getUserAppointments = useCallback(async () => {
    if (!userData) return;

    try {
      const q = query(
        collection(db, "userAppointments"),
        where(
          userData?.role === "patient" ? "userId" : "serviceId",
          "==",
          userData.uid
        )
      );
      const querySnapshot = await getDocs(q);
      const appointments = [];
      const today = new Date();
      today.setHours(24, 0, 0, 0);

      querySnapshot.forEach((doc) => {
        const currentData = doc.data();
        const bookedDate = new Date(currentData.bookedDate);

        if (bookedDate >= today && !doc.isCompleted) {
          appointments.push({ id: doc.id, ...currentData });
        }
      });
      const result = sortAppointmentsByDateAndTime(appointments);
      setAppointmentList(result);
      setLoading(false);
    } catch (error) {
      console.error("Error while getting appointments:", error);
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      getUserAppointments();
    }
  }, [userData, getUserAppointments]);

  return (
    <SidebarWrapper>
      <Typography
        variant="h5"
        sx={{ color: customColors.color_primary }}
        gutterBottom
      >
        Upcoming Appointments
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : appointmentList.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {appointmentList.map((appointment) => (
            <AppointmentCard
              key={appointment.id}
              appointment={appointment}
              userData={userData}
              onClick={() =>
                console.log(`Navigate to appointment ${appointment.id}`)
              }
            />
          ))}
        </Box>
      ) : (
        <Typography variant="h6" sx={{ color: customColors.color_red }}>
          No Appointments!
        </Typography>
      )}
    </SidebarWrapper>
  );
};

export default AppointmentsPage;
