import { LogoutOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import allHomeImages from "../../assets/home/allHomeImages";
import { customColors } from "../../assets/styles/Theme";
import { AuthContext } from "../../contexts/Auth.context";
import { auth } from "../../utils/firebase/firebaseConfig";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";

const AuthNavbar = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const { topLabel } = useContext(ActiveSidebarContext);

  const [logoutLoading, setLogoutLoading] = useState(false);

  // Sign out user
  const handleSignOut = () => {
    setLogoutLoading(true);
    signOut(auth)
      .then(() => {
        toast.success("Signed out successfully");
        localStorage.removeItem("uid");
        navigate("/signin");
        setLogoutLoading(false);
      })
      .catch((err) => {
        setLogoutLoading(false);
        toast.error(err?.message);
      });
  };
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: customColors.color_primary,
        display: { md: "flex", xs: "none" },
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem 1.5rem",
      }}
    >
      <Box component={Link} to={"/"} sx={{ width: "13%" }}>
        <img
          src={allHomeImages.logo}
          alt="Wise Doc"
          style={{ width: "7vmax" }}
        />
      </Box>

      <Box
        sx={{
          width: "84.5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" sx={{ color: customColors.color_white }}>
          {topLabel}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <Link to={"/profile"}>
            <Avatar src={userData?.avatar} />
          </Link>

          <LoadingButton
            variant="outlined"
            loading={logoutLoading}
            disabled={logoutLoading}
            color={customColors.color_white}
            endIcon={<LogoutOutlined />}
            onClick={handleSignOut}
            sx={{
              color: customColors.color_white,
            }}
          >
            Logout
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
};

export default AuthNavbar;
