import { SendOutlined } from "@mui/icons-material";
import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { formatChatTime } from "../../utils/others/calendar.utils";

const DoctorMessages = () => {
  const navigate = useNavigate();
  const { receiver } = useParams();

  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (!receiver) {
      navigate(-1);
    }
    setShowBottomNavigator(false);
    setTopLabel("Chat");
    setActiveSidebarText("chat-room");
  }, [
    receiver,
    navigate,
    setShowBottomNavigator,
    setTopLabel,
    setActiveSidebarText,
  ]);

  const getAllMessages = async (startLoading) => {
    try {
      if (startLoading) {
        setLoading(true);
      }

      const messagesSentByUser = query(
        collection(db, "messages"),
        where("sender", "==", userData.uid),
        where("receiver", "==", receiver)
      );

      const messagesSentByReceiver = query(
        collection(db, "messages"),
        where("sender", "==", receiver),
        where("receiver", "==", userData.uid)
      );

      const snapshot1 = await getDocs(messagesSentByUser);
      const snapshot2 = await getDocs(messagesSentByReceiver);

      const combinedMessages = [
        ...snapshot1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...snapshot2.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      const sortedMessages = combinedMessages.sort(
        (a, b) => a.timestamp - b.timestamp
      );

      setAllMessages(sortedMessages);
      setLoading(false);
    } catch (error) {
      console.log("Error while getting messages:", error);
      setLoading(false);
    }
  };

  const getReceiverData = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", receiver));
      if (userDoc.exists()) {
        const receiverData = userDoc.data();
        setTopLabel(
          receiverData?.role === "doctor" &&
            !receiverData?.firstName?.toLowerCase()?.startsWith("dr.")
            ? `Dr. ${receiverData?.firstName}`
            : receiverData?.firstName
        );
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  useEffect(() => {
    if (receiver) {
      getAllMessages(true);
      getReceiverData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiver]);

  useEffect(() => {
    const intervalId = setInterval(() => getAllMessages(false), 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (receiver && message.trim() !== "") {
        setSending(true);
        const newMessageObj = {
          message,
          receiver,
          sender: userData.uid,
          new: true,
          timestamp: Date.now(),
        };

        await addDoc(collection(db, "messages"), newMessageObj);
        setMessage(""); // Clear the input
        getAllMessages(false);
        setSending(false);
      }
    } catch (error) {
      toast.error("An error occurred while sending message.");
      console.error(error);
      setSending(false);
    }
  };

  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              margin: "16px",
            }}
          >
            {allMessages.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent:
                    item.sender === userData.uid ? "flex-end" : "flex-start",
                }}
              >
                <Paper
                  key={index}
                  sx={{
                    backgroundColor:
                      item.senderId === userData.uid
                        ? customColors.color_primary
                        : customColors.color_white,
                    color:
                      item.senderId === userData.uid
                        ? customColors.color_white
                        : customColors.color_gray,
                    padding: 1,
                    width: "auto",
                    maxWidth: "60%",
                    borderRadius: "0.3rem",
                    marginY: "0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color:
                        item.senderId === userData.uid
                          ? customColors.color_white
                          : customColors.color_gray,
                      fontWeight: 500,
                    }}
                  >
                    {item.message}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 9,
                      textAlign: "right",
                      color: customColors.color_gray,
                    }}
                  >
                    {formatChatTime(item.timestamp)}
                  </Typography>
                </Paper>
              </Box>
            ))}
          </Box>

          <Box
            component="form"
            onSubmit={sendMessage}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem 0.7rem",
              borderTop: "1px solid #dddddd",
              backgroundColor: "#fff",
              gap: 2,
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              sx={{ flex: 1 }}
            />
            <IconButton
              onClick={sendMessage}
              type="submit"
              disabled={sending}
              sx={{
                backgroundColor: customColors.color_primary,
                ":hover": { backgroundColor: customColors.color_primary },
              }}
            >
              <SendOutlined sx={{ color: customColors.color_white }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </SidebarWrapper>
  );
};

export default DoctorMessages;
